.last-message-container {
    margin-top: 3px;
  }

.last-message-text{
    font-size: 12px;
    color: rgb(254, 254, 254);
}

.last-message-timestamp{
    font-size: 12px;
    color: rgb(255, 255, 255);
}