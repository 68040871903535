/* Container for the input and button */
.comment-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between input and button */
    padding: 10px;
    background-color: #1a1a1a; /* Light background */
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 600px; /* Optional: limit width for a neat appearance */
    margin: 0 auto; /* Center the container */
  }
  
  /* Input field styles */
  .comment-input {
    flex: 1; /* Take up available space */
    padding: 8px 12px; /* Comfortable padding */
    font-size: 14px; /* Readable font size */
    border: 1px solid #000000; /* Light border */
    border-radius: 15px; /* Smooth edges */
    outline: none; /* Remove default outline */
    transition: border-color 0.3s ease; /* Smooth focus effect */
    background-color: #000000;
    color: #fff;
  }
  
  /* Change border color on focus */
  .comment-input:focus {
    border-color: #c6f312; /* Slightly noticeable focus color */
  }
  
  /* Placeholder text styling */
  .comment-input::placeholder {
    color: #aaa; /* Subtle placeholder color */
  }
  
  /* Button styles */
  .comment-button {
    padding: 8px 16px;
    font-size: 14px;
    color: #131313; /* White text */
    background-color: #c6f312; /* Blue button */
    border: none; /* Remove border */
    border-radius: 15px; /* Smooth edges */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  /* Button hover effect */
  .comment-button:hover {
    background-color: #c6f312; /* Darker blue on hover */
  }
  
  /* Disabled button state */
  .comment-button:disabled {
    background-color: #303030; /* Light gray for disabled */
    cursor: not-allowed; /* Not-allowed cursor for disabled */
  }
  