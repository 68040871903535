
.ContInputFeed {
    background-color: rgba(0, 0, 0, 0.90);
    display: flex;
    flex-direction: row;
}


.inputFeed{
    padding: 18px 26px; /* Vertical and horizontal padding */
    font-size: 16px; /* Text size */
    border: none; /* Remove default border */
    outline: none; /* Remove the outline to prevent default focus styles */
    border-radius: 20px; /* Rounded borders */
 
    width: 95%; /* Width of the search bar */
    background-color: #111; /* Dark background color */
    color: #c9c9c9; /* White text color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle box shadow */
  }