.mayorCont {
    position: relative;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
    z-index: 999; /* Ensure it's behind the modal but above other elements */
}

.modalContSetting {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(30, 30, 30);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: 200px;
    z-index: 1000; /* Ensure it's above the overlay */
}

.texEditA {
    color: rgb(237, 237, 237);
    font-size: 16px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
}

.texEditA:hover {
    background-color: rgb(20, 20, 20);
    border-radius: 20px;
}

.btn {
    border: none;
    background-color: inherit;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    color: rgb(228, 228, 228);
}

.btn:hover {
    background-color: rgb(20, 20, 20);
    border-radius: 20px;
    color: rgb(228, 228, 228);
}
