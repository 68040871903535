.inputTextCon {
    display: flex;
    justify-content: center; /* Center the search bar horizontally */
    margin-left: 10px; /* Left margin */
    margin-right: 10px;
  }
  
  .inputText {
    padding: 12px 20px; /* Vertical and horizontal padding */
    font-size: 16px; /* Text size */
    border: none; /* Remove default border */
    outline: none; /* Remove the outline to prevent default focus styles */
    border-radius: 20px; /* Rounded borders */
    min-width: 95%;
    height: 50px;
    background-color: #202020; /* Dark background color */
    color: #eae8e8; /* White text color */
    resize: none; 
  }

  @media (max-width: 767px) {
    .inputText {
      font-size: 16px; /* Smaller text size for mobile devices */
      /* Adjust padding for mobile devices */
    }
  }
  
  /* For tablets or iPads */
  @media (min-width: 768px) and (max-width: 1023px) {
    .inputText {
      font-size: 16px; /* Medium text size for iPads */
       /* Adjust padding for iPads */
    }
  }
  
  /* For desktops */
  @media (min-width: 1024px) {
    .inputText {
      font-size: 18px; /* Larger text size for desktop screens */
       /* Adjust padding for desktop screens */
    }
  }
  .sendButtonoffi {
    width: 60px;
   height: 50px;
  
    border-radius: 40px;
   background-color: #000000;
    border: none;
    color: rgb(0, 0, 0);
    
    
    cursor: pointer;
 
    
}

 
 