.no-more-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  margin-top: 20px;
  height: 100%; /* Ensures the container takes up full height if necessary */
}

.no-more-icon {
  margin-bottom: 10px;  
  fill: #dbdbdb;
}

.reload-icon {
  fill: #dbdbdb;
}


.reload-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.reload-button:hover {
  background-color: #0056b3;
}

.reload-button svg {
  margin-right: 5px;
}

.ttr{
  color: #dbdbdb;
}