/* Container for both user and company profiles in comments */
.comments-profile-container {
    display: flex;
    flex-direction: column;
   
     
  }
  
  /* Styles for the profile info section for comments */
  .comments-profile-info, .comments-company-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px; /* Space between user/company profiles */
    
    
    border-radius: 8px;
  }
  
  /* Profile image styles in comments */
  .comments-profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circular image */
    margin-right: 10px;
    object-fit: cover; /* Ensures the image fits within the container */
  }
  
  /* Default icon style for users/companies without an image in comments */
  .comments-default-icon {
    margin-right: 10px;
    color: #888888; /* Gray color for the default icon */
  }
  
  /* Profile and company name styling for comments */
  .comments-profile-name, .comments-company-name {
    font-size: 14px;
    color: #ffffff; /* Light color for better contrast */
    font-weight: bold;
  }
  