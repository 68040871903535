/*


.messageContainer55 {
  display: flex;
  flex-direction: column;
}

.message1 {
  max-width: 80%;
  color: #000;
  padding: 8px 12px;
  border-radius: 20px;
  margin: 10px;
  word-wrap: break-word; 
}

 

.sentByPartner {
  align-self: flex-start;
  margin: 5px 0;
  color: #bdbdbd;
  background-color: #000000;
  border: 1px solid #2b2b2b;  
}


.date {
  text-align: center;
}


 
@media (max-width: 767px) {
  .conChatChido1 {
    height: 79vh;  
    overflow-y: auto;
    overflow-x: auto; 
   
    min-width: 100%;
    max-width: 100%;
  }
}

 
@media (min-width: 768px) and (max-width: 1023px) {
  .conChatChido1 {
    max-height: 400px;
    min-width: 70%;
    max-width: 70%;
    overflow-y: auto;
  }
}

 
@media (min-width: 1024px) {
  .conChatChido1 {
    max-height: 400px;
    min-width: 600px;
    max-width: 600px;
    overflow-y: auto;
  }
}

::-webkit-scrollbar {
  width: 6px; 
}

 
::-webkit-scrollbar-track {
  background: #000000;  
}
 
::-webkit-scrollbar-thumb {
  background: #000000; 
  border-radius: 5px;  
}

 
::-webkit-scrollbar-thumb:hover {
  background: #000000; 
}



.chat-image, .chat-video, .chat-pdf {
  max-width: 100%;
  border-radius: 5px;
}


.chat-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.chat-pdf a {
  color: #007bff;
  text-decoration: none;
}

.chat-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.chat-pdf canvas {
  width: 100% !important;
  height: auto !important;
}
 

*/ /* Message container styling */
.messageContainer55 {
  display: flex;
  flex-direction: column;
}

/* Message bubble styling */
.message1 {
  max-width: 80%;
  color: #000;
  padding: 8px 12px;
  border-radius: 20px;
  margin: 10px;
  word-wrap: break-word;
}

.sentByYou {
  align-self: flex-end;
  color: #ffffff;
  background-color: #000000;
  box-shadow: 0px 0px 10px 2px #a48edc;
  margin-right: 20px;
}

.sentByPartner {
  align-self: flex-start;
  margin: 10px 10px;
  color: #fffdfd;
  background-color: #000000;
  border: 1px solid #2b2b2b;
  box-shadow: 0px 0px 10px 2px #ffffff;
}

/* Date styling */
.date {
  text-align: center;
}

/* Styles for chat attachments */
.chat-image, .chat-video, .chat-pdf {
  width: auto;
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 15px;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Style for chat PDFs */
.chat-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.chat-pdf a {
  color: #007bff;
  text-decoration: none;
}

.chat-pdf canvas {
  width: 100% !important;
  height: auto !important;
}

/* Map (iframe) styling */
.chat-location iframe {
  width: 100%; /* Make the map take full width */
  max-width: 100%; /* Prevent overflow */
  height: 300px; /* Set a fixed height */
  border: 2px solid #000; /* Add a border to match the message */
  border-radius: 15px; /* Rounded corners for consistency */
  margin-top: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3); /* Optional shadow effect */
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .conChatChido1 {
    height: 79vh;
    overflow-y: auto;
    overflow-x: auto;
    min-width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .conChatChido1 {
    max-height: 400px;
    min-width: 70%;
    max-width: 70%;
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .conChatChido1 {
    max-height: 400px;
    min-width: 600px;
    max-width: 600px;
    overflow-y: auto;
  }
}
