.cabeseraChat {
    display: flex;
    align-items: start;
    min-width: '100%';
    
  }
  
  .chatIconContainer {
    background-color: #111;
    padding: 8px;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .imgProfileContainer {
    margin-left: 10px;
  }
  

  .noChatsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    text-align: center;
    color: #000000;
    background-color: #000000;
    border-radius: 20px;
    padding: 20px;
  }
  
  .noChatsIcon {
    margin-bottom: 10px;
    fill: #000000;
  }
  
  .noChatsMessage {
    font-size: 18px;
    font-weight: bold;
    color: 'red';
    background-color: #b79df9;
    padding: 5px;
  }