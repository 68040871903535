/* Dark, minimalistic background */
.container123 {
    position: relative; /* Important for positioning scroll buttons */
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 20px;
    background-color: #121212; /* Dark background */
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
    
}

.scrollWrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of items */
    scroll-behavior: smooth; /* Smooth scroll behavior */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.scrollWrapper::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}
/* Minimalistic post card */
.post456 {
    display: inline-block;
    margin: 10px;
    background-color: #1f1f1f; /* Dark background */
    border-radius: 8px; /* Slightly rounded corners */
    padding: 15px;
    min-width: 250px;
   
    height: 140px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease; /* Smooth hover transition */
}

/* Hover effect: lighter background and subtle scale */
.post456:hover {
    background-color: #2a2a2a;
    transform: translateY(-3px); /* Slight lift */
}

.navigateBtn789 {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: inherit;
}

.column011 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; 
    gap: 10px; 
}

/* Profile image with minimal border */
.imageProfile212 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #e0e0e0; /* Subtle border for contrast */
}

.searchIcon987 {
    margin-right: 10px;
    color: #e0e0e0;
}

.userText543 {
    font-weight: bold;
    color: #f0f0f0; /* Slightly brighter text */
    font-size: 14px; /* Small, clean font */
}

.description246 {
    margin-top: 10px;
    font-size: 13px;
    color: #a0a0a0; /* Muted text for description */
    font-weight: 300; /* Lighter text for a minimalistic feel */
}

.columnUser852 {
    margin-top: 10px;
    display: flex;
    justify-content: space-between; /* Spread items horizontally */
    align-items: center;
}

.likesSection370 {
    font-size: 12px;
    color: #a0a0a0;
    text-align: center; /* Horizontally centers the text */
}


/* Hide scrollbar for cleaner look */
.container123::-webkit-scrollbar {
    display: none;
}

.container123 {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Smooth scrolling */
.scrollWrapper {
    scroll-behavior: smooth;
}

/* Remove button appearance and simplify messaging button */
button {
    background: none;
    border: none;
    color: #f0f0f0;
    font-size: 14px;
}

button:hover {
    color: #a0a0a0; /* Subtle hover effect */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .post456 {
        width: 180px;
    }
}

/* Scroll buttons */
.scrollButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.scrollButton.left {
    left: 10px;
}

.scrollButton.right {
    right: 10px;
}

/* Hide scroll buttons on mobile */
@media (max-width: 768px) {
    .scrollButton {
        display: none;
    }
}

/* Show scroll buttons on larger screens */
@media (min-width: 769px) {
    .scrollButton {
        display: block;
    }
}

