.company-cv-container435 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.company-cv-header435 {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 10px 10px 0 0;
}

.Csection-1 {
  background-color: #CDED17;
}

.Csection-2 {
  background-color: #DF18ED;
}

.Csection-3 {
  background-color: #5418ED;
}

.header-icon435 {
  font-size: 40px;
  color: #495057;
  margin-right: 10px;
}

.company-cv-title435 {
  font-size: 18px;
  font-weight: 500;
}

.company-cv-content435 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.company-cv-panel435 {
  flex: 1 1 45%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.detail-icon435 {
  margin-bottom: 5px;
  color: #495057;
}

.cv-text {
  margin: 5px 0;
  font-size: 14px;
  color: #495057;
}
