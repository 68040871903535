 .posts-contTable {
  display: grid;
   
  background-color: #000000;
  grid-template-columns: repeat(3, 1fr);
  
  gap: 5px; 
}

.conItemPosTa {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  height: min-content; /* Adjust height based on content */
  border-radius: 15px;
  overflow: hidden; 
  border: 1px solid rgb(21, 21, 21);
 
 
}

.conItemPosTa:hover {
  background-color: rgb(13, 13, 13);
}


 
@media only screen and (max-width: 600px) {
  .posts-contTable {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
  }
}

/* For iPads */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .posts-contTable {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on iPad */
  }
}

/* For PC */
@media only screen and (min-width: 1025px) {
  .posts-contTable {
    grid-template-columns: repeat(3, 1fr); /* 4 columns on PC */
  }
}




.tesDa {
  
}

/* Table.css */

.filtered-image {
  filter: brightness(5%);
}

.PSdescription{
   
  color: #cfcfcf;
  font-size: 10px;
  text-align: center;

  border-radius: 20px;
}

.aaa55 {
  display: flex;
  flex-direction: column; /* Arrange items in a column */
}


.cityMONUNcon{
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  justify-content: space-between; /* Space between columns */
}


.columnMC{
  width: calc(50% - 10px); /* 50% width for each column with some margin in between */
  margin-bottom: 10px; /* Margin between rows */
}

 
 

.subTitle {
  text-align: center;
  color: #e0e0e0;
  background-color: rgb(26, 26, 26);
  padding: 5px;
  border-radius: 20px;
}

.generico {
  text-align: center;
}

.ContImage {
  background-color: rgb(255, 255, 255);
}

.imgPost {
  width: 200px;
  height: 200px;
  border-radius: 5px;
}

.imgPerfil {
  border-radius: 50px;
  width: 100px;
  height: 100px;
}

.clickalicious-button2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:transparent;
  border: none;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}


.post-00ADD {
  color: #ffffff;
  padding: 5PX;
  text-align: center; 
   font-size: 14px;
  border-radius: 10px;
}

.post-00 {
  color: #ffffff;
  padding: 5PX;
  text-align: center; 
  border-radius: 10px;
}

.postSS {
  color: #d4d4d4;
 
  text-align: center; 
  border-radius: 10px;
}

.Weeklyt {
  color: #5e5e5e;
 margin-top: 10px;
  text-align: center; 
  border-radius: 10px;
  font-size: 10px;
}

 
/* Table.css */

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eTP {
  color: #d0d0d0;
}


.post-imageTa {
  width: 100%; /* Makes the image take the full width of the container */
  height: auto;
  border-radius: 8px;
 
   /* Adds space between the image and the title */
}


.blackDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(226, 108, 108, 0.5); /* Black with 50% opacity */
 
}



.show {
  display: block; /* Show the black div when activated */
}



.buttlogo5{
  display: flex;
  align-items: center; /* This will vertically center your icon */
  justify-content: center; /* This will horizontally center your icon */
  /* Add any additional styling you need for the button here */
   
  border: none; /* Removes the border */
  background-color: transparent; /* Example background, adjust as needed */
  cursor: pointer; 
  
  margin-left: 10px;
 
  border: none;
  cursor: pointer;
 
}

.post-videoTa {
  width: 100%; /* Make the video take the full width of the container */
  height: auto; /* Adjust height based on the video's aspect ratio */
  border-radius: 10px; /* Rounded corners for the video */
  margin-top: 10px; /* Space between the video and the content above */
}
