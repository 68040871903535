/*.posts-container {
  column-count: 3;
  column-gap: 0;
  width: 100%;
  background-color: #000000;
}

.post1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  width: 310px;  
  height: min-content;  
  border-radius: 15px;
  overflow: hidden; 
  border: 1px solid rgb(21, 21, 21);
  margin: 8px 4px;
  padding: 5px;
}*/

.posPro3may {
  display: flex;
  margin-top: 5px;
 
}



@media only screen and (max-width: 600px) {
  .posPro3may {
    margin-bottom: 120px;
  }
}


.conInPro {
  background: #000000;
  width: 100%;
  border-radius: 10px;
 
  
}


.posts-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto); /* Adjust based on your content */
  grid-gap: 10px;
  background-color: #000000;
  align-items: end;
  grid-auto-flow: dense;
}

@media (max-width: 600px) {
  .posts-container1 {
    grid-template-columns: repeat(2, 1fr);

  }
  .conInPro {
    width: 100%;
  }
}


.postPr3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  width: 100%; /* Take up full grid cell width */
  border-radius: 15px;
  overflow: hidden; 
  border: 1px solid rgb(21, 21, 21);
  padding: 5px;
}
  

  .post-info {
    display: flex;
    flex-direction: column;
  }


  .post-header {
    display: flex;
    align-items: center;
  }
  
  .post-avatar {
    width: 200px;
    height: 200px;
    border-radius: 5;
    margin-right: 10px;
  }
  
 
  
  .post-info  {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .post-info  {
    margin: 0;
    font-size: 12px;
    color: #777;
  }
  
  .post-content {
    margin: 10px 0;
    font-size: 14px;
  }

 
 .post-image-3{
    border-radius: 10px;
 }

 .post-title54{
  color: #ff6d6d; /* Change the text color */
  background-color: transparent; /* Remove the background color */
  border: none; /* Remove the border */
  cursor: pointer; /* Change the cursor to a hand when you hover over the button */
  font-size: 14px;
 }
   

 .expandButton {
  color: #5a5858; /* Change the text color */
  background-color: transparent; /* Remove the background color */
  border: none; /* Remove the border */
  cursor: pointer; /* Change the cursor to a hand when you hover over the button */
  font-size: 14px;
}

.post-video-3 {
  width: 100%;
  height: auto;
  border-radius: 15px; /* Adjust the radius as needed */
}

.post-video-3:hover {
  cursor: pointer;
}


.post-video65 {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensures video never exceeds container width */
  border-radius: 8px;
  background-color: #000000; /* Placeholder background */
  object-fit: cover; /* Ensures the video maintains its aspect ratio */
}

/* For smaller mobile screens */
@media (max-width: 480px) {
  .post-video65 {
    width: 100%;
    height: auto;
  }
}

/* For tablets or medium devices */
@media (min-width: 481px) and (max-width: 768px) {
  .post-video65 {
    width: 100%;
    height: auto;
  }
}

/* For larger screens like desktops */
@media (min-width: 769px) {
  .post-video65 {
    width: 100%;
    height: auto;
  }
}

/* For extra-large desktop screens */
@media (min-width: 1400px) {
  .post-video65 {
    width: 100%;
    height: auto;
  }
}
