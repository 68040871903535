.cont-suscription {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
  }
  
  .email {
    color: #6c757d;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .free-posts {
    color: #28a745;
    font-size: 20px;
    font-weight: bold;
  }
  