/* NotFound.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Use the full viewport height */
    font-family: 'Arial, sans-serif';
    text-align: center;
    margin: 0;
}

.not-found-title {
    color: #2c3e50;
    font-size: 20px
}

.not-found-text {
    font-size: 15px;
    color: #95a5a6;
}
.not-found-sub{
    margin-top: 20px;
}