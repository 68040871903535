.containerSuscription {
    display: flex;
    flex-wrap: wrap;
    background-color: black;
    height: 100vh; /* Full height */
    width: 100vw; /* Full width */
}


.columnCH {
    flex: 50%;
    padding: 20px;
  
}


.conttRecruitment {
    text-align: end;

}

.SubscribeNOW{
    font-weight: bold;
    font-size: 20px;
    color: #000;
}




@media screen and (max-width: 600px) {
    .column {
        flex: 100%;
    }
}

.conlogoSuscr{
    display: flex;
    justify-content: end;
}


.conBBSuscr{
    margin-top: 20PX;
    display: flex;
    justify-content: end;
 
}

.iiconsus{
    fill: #4F8EF7;
}




.logoSuscr{
    width: 150px;
    height: 150px;
}

.ttRecruitment{
    color: #fff;
    font-weight: bold;
    font-size: 30px;
}

.ttRe{
    color: #fff;
    font-weight: bold;
    font-size: 15px;
}

.ttmm{
    color: #8a8a8a;
    font-size: 15px;
}


.ttcancel{
    color: #808080;
    font-weight: bold;
}

.coludd{
    display: flex;
    border: 1px solid #141414;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;
}

.mindd {
    flex: 1;
}



.glow-on-hoverSS {
    width: 200px;
    height: 60px;
    border: none;
    outline: none;
    color: #f7f6f6;
    background: #fffb00;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 50px;
 
  }
  
  .glow-on-hoverSS:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 50px;
  }
  
  .glow-on-hoverSS:active {
    color: #000;
  }
  
  .glow-on-hoverSS:active:after {
    background: transparent;
  }
  
  .glow-on-hoverSS:hover:before {
    opacity: 1;
  }
  
  @keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
  }
  

  .dddsfs{
    text-decoration: none;
  }