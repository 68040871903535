/* CSS */
.profiles-postC7 {
    display: flex;
    flex-direction: row; /* Arrange children in a row on large screens */
    justify-content: space-between; /* Add space between the columns */
    background-color: #000;
    height:100vh;
  }
  
  .posCon1Edit,
  .posCon2Edit {
    box-sizing: border-box; /* Each child should take up an equal amount of space */
    margin: 10px; /* Add some margin around each column */
    margin-top: 20px;
    
  
  }
  
  @media (max-width: 768px) {
    /* On small screens (like mobile devices), arrange children in a column */
    .profiles-postC7 {
      flex-direction: column;
      width: 100vw;
      margin-bottom: 100px;
      
    }
  }

  @media (min-width: 768px) {
    .posCon1Edit,
    .posCon2Edit {
        width: 100vw
    }
  }
  
  






.edit-container {
    max-width: 480px;
    margin: 30px auto;
  
   
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.edit-form  {
    color: #333;
    font-weight: 300;
    text-align: center;
    margin-bottom: 2rem;
}
.parent-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .profile-back33 {
    margin-top: 20px;
    border-radius: 20px;
    width: 40%; /* This will make the image width fit its container */
    height: 100%; /* This will make the image height fit its container */
    object-fit: cover; /* This will crop the image to cover the container while maintaining its aspect ratio */
    margin-bottom: 20px;
  }
  

 
.form-row {
    /* Other styles... */
    margin-bottom: 15px; /* Adjust as needed */
  }

.profile-preview33 {
    width: 100px;
    height: 100px; /* Set the height to match the width for a circular shape */
    border-radius: 50%; /* Set to 50% for a circular shape */
    object-fit: cover; /* Ensures the image covers the entire container without stretching */
    margin-bottom: 1rem;
}

.upload-input {
    display: none;
}

.upload-label {
    background-color: #f5f5f7;
    color: #000;
    padding: 0.5rem 1rem;
    text-align: center;
    display: block;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid #d1d1d6;
    margin-bottom: 1rem;
}

.text-input {
    border: none;
    border-bottom: 1px solid #d1d1d6;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #333;
    background-color: transparent;
}

.input-label {
    color: #d4d4d6;
    font-size: 0.875rem;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.default-div{
    display: flex;
    flex-direction: column; /* Stack the children vertically */
    justify-content: center; /* Center the children vertically */
    align-items: center;
    margin-top: 20px;
     border-radius: 20px;
     width:    300px;
     height: 150PX;
     border: 2px solid rgb(217, 217, 217);
     text-align: center;

}



.default-div-profile{
    display: flex;
    flex-direction: column; /* Stack the children vertically */
    justify-content: center; /* Center the children vertically */
    align-items: center;
    margin-top: 20px;
     border-radius: 150px;
     width:    150px;
     height: 150PX;
     border: 2px solid rgb(217, 217, 217);
     text-align: center;

}


.t34{
    color: #fff;
}


.action-button, .cancel-button {
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
}

.action-button {
    background-color: #007aff;
    color: #fff;
}

.cancel-button {
    background-color: transparent;
    color: #007aff;
    box-shadow: none;
}

.action-button:hover, .cancel-button:hover {
    opacity: 0.8;
}

.buttonSudmitCansel7 {
     
        width: 80px;
        height: 50px;
        border: 1px solid #6d6d6d; /* Adding border */
        outline: none;
        color: #dddddd;
        background: #000000;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 50px;
   
      
  }
  