.history-post-container {
    display: flex;
    gap: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.history-left-column, .history-right-column {
    flex: 1;
}

.history-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.history-cancel-btn, .history-add-btn {
    background-color: transparent;
    border: 2px solid #007BFF;
    color: #007BFF;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.history-cancel-btn:hover, .history-add-btn:hover {
    background-color: #007BFF;
    color: white;
}

.history-textarea-container {
    margin-bottom: 20px;
}

.history-textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    font-size: 16px;
}
.progress{
    max-height: 10px;
}

.history-progress-bar {
    background-color: #303030;
    border-radius: 5px;
    overflow: hidden;
    height: 8px;
}

.history-progress {
    background-color: #e6e6e6;
    height: 100%;
    transition: width 0.4s ease;
}

.history-media-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    border-radius: 8px;
    height: 200px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.history-media-upload:hover {
    background-color: #f0f0f0;
}

.history-media-container {
    position: relative;
}

.history-change-media-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.history-video-preview, .history-image-preview {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.history-image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #aaa;
}
