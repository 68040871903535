
/*
.container-query-maind {
    display: grid;
    gap: 2px; 
}

@media only screen and (max-width: 600px) {
    .container-query-maind {
      grid-template-columns: repeat(1, 1fr);  
    }
  }
  
   
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .container-query-maind {
      grid-template-columns: repeat(2, 1fr);  
    }
  }
  
   
  @media only screen and (min-width: 1025px) {
    .container-query-maind {
      grid-template-columns: repeat(3, 1fr);  
    }
  }

.item-query {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000000;
    height: min-content;  
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgb(21, 21, 21);
    padding: 5px;
}

.image-all-sizes {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  

.cityMONUNcon3 {
    display: grid;
    grid-template-columns: auto auto;  
    gap: 10px;  
    align-items: center;  
  }
  
  
 
.video-container {
  width: 100%;
  max-width: 100%;  
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;  
}

.video-all-sizes {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #000; 
}
*/
.container-query-maind {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 15px; /* Increase the gap for clarity */
  padding: 15px;
}

.item-query {
  background-color: #000; /* Black background */
  color: white; /* Ensure text is visible on black background */
  padding: 15px; /* Add padding for content */
  border-radius: 10px; /* Rounded corners for cards */
  overflow: hidden;
  border: 1px solid #151515;
}

.image-all-sizes, .video-all-sizes {
  width: 100%; /* Ensure full width responsiveness */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

/* Button and additional elements styling */
.cityMONUNcon3 {
  display: flex;
  justify-content: space-between; /* Ensure even spacing between buttons */
  margin-top: 10px;
}

.navigatePerfilButton, .CCS4 {
  flex: 1;
  text-align: center; /* Center text inside the buttons */
}

/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  .container-query-maind {
    grid-template-columns: repeat(1, 1fr); /* Single column on small screens */
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .container-query-maind {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }
}

@media only screen and (min-width: 1025px) {
  .container-query-maind {
    grid-template-columns: repeat(3, 1fr); /* Three columns for desktops */
  }
}
