/*
 


.twitter-profile {
  background: #000000;
  
  border-radius: 10px;
  margin-right: 5px;
  border: 1px solid #141414; 
  margin-top: 5px;
 

}
 
 .mobile-only {
  display: none;
  
}

 
@media (max-width: 768px) {
  .mobile-only {
    display: block;
    margin-top: 10px;
  }
}

 

 
.pc-only {
  display: none;
  margin: 20px;
}

 
@media (min-width: 769px) {
  .pc-only {
    display: block;
  }
}

 
 


.profileIMAGEedi {
  width: 100%;  
  height: auto; 
  object-fit: cover;  
}



.profile-details {
 background-color: rgb(26, 26, 26);
  align-items: center;
  display: flex;
  border-radius: 100px;
   
  margin-top: 10px;
}



.profile-image5{
  margin:20PX;
}

.conPimg {
  width: 100px;  
  height: 100px;  
  border-radius: 50%;  
  object-fit: cover;

}
 
.profile-city-tex{
  color: #a887fc  ;
  font-size: 14px;
  margin-right: 10px;
  display: -webkit-box;         
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 3;        
  overflow: hidden;          
  text-overflow: ellipsis;      
  height: auto;

}

.descri {
  color: #e7e7e7;
  font-size: 14px;
  margin-right: 10px;
  display: -webkit-box;         
  -webkit-box-orient: vertical; 
  overflow-y: auto;             
  height: 60px;                 
  line-height: 1.5;             
  scrollbar-width: thin;     
  scrollbar-color: #1d1d1d #030303;   
}

 
.descri::-webkit-scrollbar {
  width: 6px;
}

.descri::-webkit-scrollbar-track {
  background: #333;            
}

.descri::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;       
  border-radius: 10px;
  border: 2px solid #333;       
}

  

.contProfileT {
  
 

 background-color: rgb(10, 10, 10);

 padding: 10px;
 border-radius: 20px;
 margin-bottom: 5px;
 
}


.profile-container-names {
  align-items: center; 
  margin-left: 10px;
}


.use32{
  color: #ffffff;
  font-size: 14px;
}

  .positionTitle{
    font-size: 15PX;
    color: rgb(196, 196, 196);
    padding: 10px;
  }


  .profile-companyName{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: bold;
  }

 

  .buttlogoPro {
    display: flex;
    align-items: center;  
    justify-content: flex-end;  
     
    height: 50px; 
    width: 50px;  
    border: none;  
    background-color: transparent;  
    cursor: pointer; 
  }
  

*/



.twitter-profile {
  background-color: #0d0d0d;
  border-radius: 10px;
  margin: 5px 0;
  border: 1px solid #141414;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-details {
  background-color: #0b0b0b;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
}

.placeholder-icon{
  color: #8b8b8b;
}

.profile-image5 {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.conPimg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #3a3a3a;
}

.profile-companyName {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

.use32, .positionTitle, .profile-city-tex, .descri {
  color: #c8c8c8;
  font-size: 14px;
  margin-top: 5px;
}

.profile-city-tex {
  color: #a887fc;
}

 

.positionTitle {
  padding: 5px 0;
}

.descri {
  margin-top: 10px;
  overflow-y: auto;
  max-height: 60px;
  scrollbar-width: thin;
  scrollbar-color: #5e5e5e #1a1a1a;
}

 
.descri::-webkit-scrollbar {
  width: 6px;
}

.descri::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  border-radius: 10px;
}

.buttlogoPro {
  background: transparent;
  border: none;
  cursor: pointer;
}

.mobile-only, .pc-only {
  display: none;
}

@media (max-width: 768px) {
  .mobile-only {
    display: block;
    margin-top: 10px;
  }
}

@media (min-width: 769px) {
  .pc-only {
    display: block;
    margin-top: 20px;
  }
}

.background-image-container {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.background-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #303030;
  border-radius: 10px;
  color: #000000;
}

.follow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust as needed */
}















/*
.profiles-containe5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
}

.twitter-profile {
  width: 100%;
  max-width: 600px;
  background-color: #000000;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 15px;
  color: #f0f0f0;
  position: relative;
}

.buttlogoPro {
  background: none;
  border: none;
  color: #f0f0f0;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.buttlogoPro:hover {
  color: #cccccc;
}

.profile-image5 {
  position: absolute;
  bottom: -40px;  
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  z-index: 10;  
}

.profile-image5 .conPimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-details {
  margin-top: 80px;  
}

.background-image-container {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1b1b1b;
}
*/