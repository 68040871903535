/*


  .con-chido-query {
    overflow: hidden;  
    background-color: black;
}

.chido_bus-2 {
    background-color: black;
}

.chido-buscador-mind {
    background-color: black;
    overflow-y: auto;  
}

@media only screen and (min-width: 768px) {
    .chido_bus-2 {
        display: flex;
        height: 100vh;
    }
}

.noFind23 {
    color: rgb(213, 214, 214);
    font-size: 20px;
    margin-left: 10px;
}


.horizontal-scroll-container {
  display: flex;
  flex-direction: row;  
  overflow-x: auto;  
  gap: 15px;  
  padding: 10px;
  white-space: nowrap;  
  scroll-behavior: smooth;  
  width: 100%;  
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;  
}

 



*/ 

 


.con-chido-query {
  overflow: hidden;  
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.query-feed-container > * {
  flex: 1 1 calc(33.33% - 20px); /* Ajusta el tamaño de los elementos */
  margin: 10px;
  background-color: #171717;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chido_bus-2 {
  background-color: black;
}

.chido-buscador-mind {
  background-color: black;
  overflow-y: auto;  
}

@media only screen and (min-width: 768px) {
  .chido_bus-2 {
      display: flex;
      height: 100vh;
  }
}

.noFind23 {
  color: rgb(213, 214, 214);
  font-size: 20px;
  margin-left: 10px;
}


.horizontal-scroll-container {
display: flex;
flex-direction: row;  
overflow-x: auto;  
gap: 15px;  
padding: 10px;
white-space: nowrap;  
scroll-behavior: smooth;  
width: 100%;  
}

.horizontal-scroll-container::-webkit-scrollbar {
display: none;  
}

 