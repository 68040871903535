/*.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 250px;
}


.message1 {
  max-width: 80%;
  color: #000;
  padding: 8px 12px;
  border-radius: 20px;
  margin: 5px;
}

.sentByYou {
  align-self: flex-end;
  color: #a1a1a1;
  background-color: #141414;
}

.sentByPartner {
  align-self: flex-start;
  margin: 5px 0;
  color: #bdbdbd;
  background-color: #000000;
  border: 1px solid #2b2b2b;  
}


.date {
  text-align: center;
}




.message-container::-webkit-scrollbar {
  width: 8px;  
}

 
.message-container::-webkit-scrollbar-track {
  background: #000000; 
}

 
.message-container::-webkit-scrollbar-thumb {
  background: #111111;  
  border-radius: 20px;
}

 
.message-container::-webkit-scrollbar-thumb:hover {
  background: #555;  
}

*/


.messageContainerFeed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message10 {
  max-width: 80%;
  color: #f2eeee;
  padding: 8px 12px;
  border-radius: 20px;
  margin: 5px;
}

.sentByYou5 {
  align-self: flex-end;
  color: #e4e4e4;
  background-color: #141414;
  /* Add any additional styling for sentByYou5 */
}

.sentByPartner5 {
  align-self: flex-start;
  margin: 5px 0;
  color: #d9d9d9;
  background-color: #000000;
  border: 1px solid #2b2b2b; /* Add border */
  /* Add any additional styling for sentByPartner5 */
}

.date {
  text-align: center;
}




.message-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Track */
.message-container::-webkit-scrollbar-track {
  background: #000000; /* Color of the track */
}

/* Handle */
.message-container::-webkit-scrollbar-thumb {
  background: #111111; /* Color of the scrollbar handle */
  border-radius: 20px;
}

/* Handle on hover */
.message-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}


.download-button {
  display: block;
  margin: 5px auto;
  padding: 5px 10px;
  background-color: #2d2d2d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #151515;
}

/* Map (iframe) styling */
.chat-location {
  width: 100%; /* Make the map take full width */
  max-width: 100%; /* Prevent overflow */
  height: 300px; /* Set a fixed height */
  border: 2px solid #000; /* Add a border to match the message */
  border-radius: 15px; /* Rounded corners for consistency */
  margin-top: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3); /* Optional shadow effect */
}
