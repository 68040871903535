.header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
 
  background-color: rgb(0, 0, 0);
  }

  .header__logo {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .icon-container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  
  
  .bottonIcon{
    background: none;
    border:  none;
    color: "#fff";
    font-weight: bold;
    cursor: pointer;
    font-size: 23px;
    color: none;
    padding: 5px 15px;
   
  }

  .idiomas{
    background: none;
    border:  none;
    color: '#fff';
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
    padding: 5px 15px;
  }

  .language-buttons{
    display: flex;
    justify-content: flex-end;
  }