.ConCompanyCv {
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(2, 2, 2, 0.1);
  height: 100vh;
  overflow-y: auto; /* Enables vertical scrolling */
}


.solicitudJob {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  margin-top: 20px;
  background-color: #442aff;
  padding: 30px;
  border-radius: 20px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .solicitudJob {
      grid-template-columns: 1fr;
  }
  .ConCompanyCv {
    height: 70vh; /* Set height to 70% of the viewport height */
    max-height: 70vh; /* Ensure it doesn't exceed 70% */
    width: 100vw;
    overflow-y: auto; /* Allows scrolling */
  }
}

.inputContainer {
  position: relative;
}

.inputIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill  : #FFF;
}

.inputTextForm, .emailSolicitudJob {
  width: 100%;
  padding: 10px;
  padding-left: 40px; /* Add padding to prevent text from overlapping with the icon */
  border: 2px solid transparent;
  border-radius: 17px;
  background-color: rgba(249, 246, 246, 0.1);
  color: rgb(255, 255, 255);
  transition: all 0.3s ease-in-out;
}

.inputTextForm::placeholder, .emailSolicitudJob::placeholder {
  color: #ffffff;
  opacity: 1;
}

.inputTextForm:focus, .emailSolicitudJob:focus {
  border-color: #FFF;
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

 

 
.progressBar45 {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #CDED17;
  transition: width 0.3s ease-in-out;
}

.SubTexAplication {
  font-size: 11px;
  color: #fff;
}

.tCompanyCv {
  color: #ffffff;
  font-weight: bold;
}

.submission-success {
  text-align: center;
  padding: 30px;
  background-color: #187BED   ; /* A green color indicating success */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Limit the width of the success message */
  margin: 0 auto; /* Center the container horizontally */
}

.cheek {
  font-size: 70px;
  color: #ffffff;
  margin-bottom: 20px;
}

.tCompanyCv {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
}

.submission-success p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 20px;
}

.btAplication {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #ffffff;
  color: #000000 ;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}
 
.btAplication:hover {
  background-color: #CDED17; /* Darker green on hover */
  transform: scale(1.05);
  color: #030303;
}

.noResize {
  resize: none;
}