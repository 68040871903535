
 
  
  
  .profile-text h2,
  .profile-text h5,
  .profile-text p {
    margin: 0;
    color: #333;
  }
  
  .profile-text h2 {
    font-size: 18px;
    font-weight: 600;
  }
  
  .profile-text h5 {
    font-size: 14px;
    color: #666;
  }
  
  .profile-text p {
    font-size: 12px;
    color: #888;
  }
  
  .edit-button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007aff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .edit-button:hover {
    background-color: #0051a8;
  }
  
  .edit-button:focus {
    outline: none;
  } 


  
 

   





  
   
