.follow-button {
    background-color: transparent; /* Transparent background for minimalist look */
    border: 2px solid #007BFF; /* Solid border for structure */
    border-radius: 20px; /* Rounded edges for a soft look */
    padding: 5px 8px; /* Space inside the button */
    font-size: 12px; /* Adjust font size for clarity */
    color: #007BFF; /* Primary color for the text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    outline: none; /* Remove the default button outline */
    font-family: 'Arial', sans-serif; /* Clean, simple font */
}

.follow-button:hover {
    background-color: #007BFF; /* Primary color on hover */
    color: #fff; /* White text on hover */
}

.follow-button:active {
    transform: scale(0.98); /* Slight scaling effect on click */
}

.follow-button:disabled {
    cursor: not-allowed; /* Disabled state with no hover effect */
    opacity: 0.6; /* Dimmed color for the disabled state */
}
