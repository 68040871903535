/*



.homepage5 {
 
  overflow: hidden;  
  background-color: rgb(0, 0, 0);
}


.homepage__navWraper5 {
   
   
  background-color: black;
}


.homepage__timeline5 {
 
  background-color: rgb(0, 0, 0);
  overflow-y: auto;  
   
}


 
@media only screen and (min-width: 768px) {
  .homepage5 {
    display: flex;
    height: 100vh;
  }
   
 
}

@media only screen and (max-width: 768px) {
  .homepage__timeline5 {
   
    margin-bottom: 100px;
  }
}



*/

/* Ensure SwipeableCard container has position relative */

.homepage5 {
 
  overflow: hidden;  
  background-color: rgb(0, 0, 0);
   
}


.homepage__navWraper5 {
   
   
  background-color: black;
}


.homepage__timeline5 {
 
  background-color: rgb(0, 0, 0);
  overflow-y: auto;  
   
}


 
@media only screen and (min-width: 768px) {
  .homepage5 {
    display: flex;
    height: 100vh;
  }
   
 
}

@media only screen and (max-width: 768px) {
  .homepage__timeline5 {
   
    margin-bottom: 100px;
  }
}
