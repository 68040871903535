/*

.input-pass-cont {
  margin-top: 20px;
  position: relative;
  width: 100%;
  margin: 0 auto;   
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 250px;
  padding: 12px 20px;   
}

.background {
  border-radius: 50px;
  width: 600px;
  padding: 50px;
}

@keyframes float {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

.input-field1 {
  animation: float 6s ease-in-out infinite;
  width: 100%;   
   
  padding: 12px 20px;
  margin: 15px auto;   
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  border: none;
 
}

.password-input {
  width: 100%; 
  border: none;
  background-color: #ffffff;   
  padding: 20px 20px;  
  border-radius: 250px;  
  box-sizing: border-box;
  flex-grow: 1;   
  color: #000000;
  font-weight: bold;
  border: 2px solid #131212; 
}

.toggle-password {
  margin-left: 0px;   
  background: none;
  border: none;
  cursor: pointer;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.background {
  background: linear-gradient(-45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-field1 {
  max-width: 350px;
  margin-bottom: 10px;
}

.conSut1 {
  text-align: center;
}

.minimalistic-button {
  margin: 10px;
  margin-top: 20px;
  background-color: transparent;
  border: 2px solid #333;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.minimalistic-button:hover {
  background-color: #333;
  color: #fff;
}

.center-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-column {
  margin-bottom: 20px;
  gap: 10px;
}

.con-tit {
  margin-bottom: 20px;
}

.conTercondi {
  margin-top: 20px;
}

.texTercondi {
  font-size: 8px;
}

.contUs {
  margin-top: 20px;
  font-size: 13px;
}


*/


.input-pass-cont {
  margin-top: 20px;
  position: relative;
  width: 100%;
  margin: 0 auto;  /* Center align with auto margin */
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 250px;
  padding: 12px 20px;  /* Add padding for a better look */
  box-sizing: border-box;
}

.input-field1 {
  animation: float 6s ease-in-out infinite;
  width: 100%;  /* Make width 100% to align with parent container */
  padding: 12px 20px;
  margin: 15px auto;  /* Center align with auto margin */
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  border: none;
}

.password-input {
  width: 100%; 
  border: none;
  background-color: #ffffff;  /* Match the background color with the email input */
  padding: 20px 20px; /* Match the padding with the email input */
  border-radius: 250px;  /* Match the border-radius with the email input */
  box-sizing: border-box;
  flex-grow: 1;  /* Allow input to take up remaining space */
  color: #000000;
  font-weight: bold;
  border: 2px solid #131212; 
}

.toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: -40px;  /* Adjust the margin to position the button inside the input field */
  padding: 0 10px;  /* Adjust padding if needed */
}

@keyframes float {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.background {
  border-radius: 50px;
  width: 600px;
  padding: 50px;
  background: linear-gradient(-45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.conSut1 {
  text-align: center;
}

.minimalistic-button {
  margin: 10px;
  margin-top: 20px;
  background-color: transparent;
  border: 2px solid #333;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.minimalistic-button:hover {
  background-color: #333;
  color: #fff;
}

.center-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-column {
  margin-bottom: 20px;
  gap: 10px;
}

.con-tit {
  margin-bottom: 20px;
}

.conTercondi {
  margin-top: 20px;
}

.texTercondi {
  font-size: 8px;
}

.contUs {
  margin-top: 20px;
  font-size: 13px;
}
