.profile-container5 {
  display: flex; /* Use flexbox for columns */
  justify-content: space-between;
}
  
  .profile-item4 {
 
    
    text-align: center;
  }

  .profile-item41{
    background-color: rgb(16, 16, 16);
    padding: 19PX 10PX;
    border-radius: 20PX;
    margin-left: 10px;
  }
 
.profile-image1 {
  width: 50px; /* profile image size */
  height: 50px; /* profile image size */
  border-radius: 50%; /* makes the image circular */
  object-fit: cover; /* ensures the image covers the area */
  border: 2px solid white; /* white border around the image */
  fill: rgb(255, 255, 255); /* background color for images with transparency */
  margin-right: 10px;  /* Add a border to the image */
  }
  
  .colorNames1{
    color: '#f2f2f2'
  }

  .p32{
    color: '#f2f2f2';
    font-weight: 'bold'
  }
