.search-container1 {
    display: flex;
    justify-content: center; /* Center the search bar horizontally */
    padding: 5px; /* Add some padding around the search bar */
   
    
  }
  
  .search-input1 {
    padding: 12px 20px; /* Vertical and horizontal padding */
    font-size: 16px; /* Text size */
    border: none; /* Remove default border */
    outline: none; /* Remove the outline to prevent default focus styles */
    border-radius: 20px; /* Rounded borders */
   min-width: 300px;
    background-color: #2a2a2a; /* Dark background color */
    color: #888888; /* White text color */
   margin-right: 10px;
  }
  
  @media (max-width: 767px) {
    .search-input1 {
      min-width: 200px; /* Adjust this value as needed */
    }
  }
  
  /* You can add transitions or other styles as needed */
  