.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Optional: to dim the background */
  z-index: 1000;
}

.content55 {
  
  overflow-y: auto; /* Enable vertical scrolling */

  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
}

@media only screen and (min-width: 769px) {
  .open-modal-button {
    display: none;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.2s;
  font-weight: bold;
}

.close-button:hover {
  color: #333;
}

.close-button:focus {
  outline: none;
}
