.comments-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #121212; /* Dark background */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6); /* Stronger shadow */
    margin-top: 20px; /* Space above the comments section */
    max-width: 800px; /* Limit width for a tidy layout */
    margin: 0 auto; /* Center the container */
    
    /* Height limitation and scrolling */
    max-height: 200px; /* Maximum height of the comments section */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.comments-container {
    width: 100%; /* Full width */
}

.comment-card {
    padding: 15px;
    margin: 10px 0; /* Vertical space between comments */
   
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 1px 3px rgba(116, 115, 115, 0.5); /* Subtle shadow */
}

.comment-text {
    font-size: 15px;
    color: #e0e0e0; /* Light text */
    margin-bottom: 5px; /* Space below the comment text */
    line-height: 1.5; /* Comfortable line height */
}

.comment-author {
    font-size: 12px;
    color: #888888; /* Gray text for author info */
}

.no-comments-message {
    text-align: center; /* Center the text */
    font-size: 14px; /* Medium font size */
    color: #bbbbbb; /* Lighter gray for the message */
    padding: 20px; /* Space around the message */
    border-radius: 8px; /* Rounded corners */
}
