.user-cv-container {
    border-radius: 10px;
    overflow: hidden;
    color: #343a40; /* Dark text color for contrast */
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Minimalistic font */
  }
  
  .user-cv-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #1c1d1d; /* Slightly darker background for header */
    border-radius: 10px 10px 10px 10px;
  }
  
  .header-icon {
    font-size: 20px; /* Smaller icon size */
    color: #495057; /* Slightly lighter color */
    margin-right: 10px;
  }
  
  .user-cv-title {
    font-size: 18px; /* Slightly smaller title */
    font-weight: 500; /* Medium weight for title */
    color: #fff;
    font-weight: bold;
  }
  
  .user-cv-content {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of panels */
    gap: 10px;
    padding: 10px;
  }
  
  .user-cv-panel {
    flex: 1 1 45%; /* Two columns */
     /* Base background color */
    padding: 10px;
    border-radius: 10px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .section-1 {
    background-color: #CDED17; /* First color */
  }
  
  .section-2 {
    background-color: #1a1a1a ; /* Second color */
  }
  
  .section-3 {
    background-color: #5418ED ; /* Third color */
  }

  .section-4 {
    background-color: #187BED  ; /* Third color */
  }

  
  .detail-icon {
    margin-bottom: 5px; /* Space between icon and text */
    color: #d9dbde; /* Consistent color with header icon */
    fill: #fff;
    font-size: 15px;
  }
  
  .user-cv-panel p {
    margin: 5px 0; /* Space between paragraphs */
    font-size: 14px;
  }
  
  a {
    color: #007bff; /* Blue link color for minimalistic look */
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .clickable {
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .clickable:hover {
    color: #007bff; /* Hover effect to match link color */
  }
  
  .minTwhite{
    color: #fff;
  }

  .DTwhite{
    color: #fff;
  }

   