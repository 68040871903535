/* Container holding the profile image */
.marcoHistoria {
  position: relative;
  display: inline-block;
  width: 70px; /* Adjusted size to accommodate the animated border */
  height: 70px;
  border-radius: 50%; /* Make the entire container circular */
}

/* Animated gradient border */
.hasStory {
  padding: 3px; /* Space for the animated border */
  border-radius: 50%; /* Circular border */
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
  background-size: 300%; /* Make the gradient flow smoothly */
  animation: animateGradient 6s linear infinite; /* Apply the animation */
}

/* Profile image inside the container */
.profileImage {
  width: 65px;
  height: 65px;
  border-radius: 50%; /* Match the container's circular shape */
  object-fit: cover;
  border: 2px solid transparent; /* Transparent inner border */
  color: #585858;
}

/* Keyframes for the gradient animation */
@keyframes animateGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
