 
 

.chatContainer1 {
  flex: 1;  
   
  background-color: rgb(0, 0, 0);
  overflow: hidden; /* Remove the scroll bar */
  height: 100vh;
}

 
.container-chatBody {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  width: 100vw; /* Width equal to 100% of the viewport width */
  height: 100vh; /* Height equal to 100% of the viewport height, adjust as needed */
  background-color: #f80e0e;
}

.ChatBody {
  flex-grow: 1; /* Allows ChatBody to take up the remaining space */
  /* Additional styling for ChatBody */
}

.stickySearch {
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Space between the two children */
  align-items: center; 
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
  z-index: 10; /* Higher than the content so it stays on top */
  background: #000000; /* Background color for the search bar */
}

.appleButton {
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #fff6f6;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.appleButton:hover {
  background-color: #9741F2 ;
  border-color: #1f1f1f;
  color: #222;
}


.iconHOME  {  
  fill: #212121;
}

.iconHOME:hover {
  fill: #141414;
}

.center-image {
  width: 50px;
  height: 50px;  
}



/* Styles for the chat list with a custom scrollbar */
.chatList {
  max-height: 580px; /* Adjust to the desired height */
  overflow-y: auto; /* Enable scrolling */
  padding-right: 5px; /* Adjust the padding to prevent content overlap with the scrollbar */
}

/* Style the scrollbar track (background) */
.chatList::-webkit-scrollbar-track {
  background-color: transparent; /* Make the track transparent */
}

/* Style the scrollbar handle */
.chatList::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  background-color: transparent; /* Make the scrollbar background transparent */
}

.chatList::-webkit-scrollbar-thumb {
  background-color: #0f0f0f; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners on the scrollbar thumb */
}

/* Style the scrollbar on hover */
.chatList::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

 
.homeButton {
  background-color: rgb(194, 194, 194); /* Remove the background color */
  border: none; /* Remove the border */
  /* Include other styling as needed, such as padding, margin, or text color */
}
