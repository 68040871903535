/* Container styling */
.toggle-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between elements */
  margin-bottom: 5px;
}

/* Button container with initial state for transition */
.buttons-container {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Button container when active */
.buttons-container.active {
  opacity: 1;
  transform: translateY(0);
}

/* Additional button styling */
.upload-button {
  cursor: pointer;
 
  padding: 10px 20px;
 
  color: white;
  border-radius: 5px;
  text-align: center;
}
