.historys-page {
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #141414;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Ensure no overflow */
}

.carousel-content {
  width: 80%;
  text-align: center;
  transition: transform 0.5s ease-in-out;
}

.carousel-button {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.story-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 90vh; /* Limit the height of the card to 90% of viewport height */
  overflow: hidden; /* Prevent content from overflowing */
}

.story-image {
  width: 50%;
  height: auto;
  max-width: 100%;
  max-height: 70vh; /* Ensure image doesn't exceed 70% of the viewport height */
  border-radius: 10px;
  object-fit: contain; /* Maintain aspect ratio */
}

.story-video {
  width: 30%;
  height: auto;
  max-width: 100%;
  max-height: 70vh; /* Ensure video doesn't exceed 70% of the viewport height */
  border-radius: 10px;
  object-fit: contain; /* Maintain aspect ratio */
}

.story-footer {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #666;
}

/* Progress bar styles */
.progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: #525252;
  margin-bottom: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #e4e4e4;
  transition: width 0.3s ease;
}

.st22 {
  color: #e9e9e9;
}

.st23 {
  color: #666;
}

.go-back-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff; /* Icon color */
}

.go-back-button:hover {
  color: #f00; /* Hover color for better UX */
}
