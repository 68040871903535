/* NoWifi.css */

.no-wifi-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: #333;
  }
  
  .no-wifi-icon {
    font-size: 48px;
    color: #808a43; /* Example color, adjust as needed */
    margin-bottom: 16px;
  }
  
  .no-wifi-container p {
    font-size: 18px;
    font-weight: bold;
    color: #474746; /* Same color as the icon for consistency */
  }
  