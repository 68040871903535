.header-container {
  display: flex;
  align-items: center;
  justify-content: end; /* Center the button horizontally */
  margin-top: 5px;
  margin-right: 5px;

}

.buttoAddJob {
  background-color: #000000;
  background: linear-gradient(90deg, #e5e4e2, #cfcfcf, #a6a6a6, #808080, #b0c4de, #d8bfd8, #e5e4e2, #cfcfcf, #e5e4e2);
  background-size: 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 150px;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;

  /* Add animation */
  animation: gradientAnimation 12s ease infinite;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

