.conChido {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {
  .conChido {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.conChido {
  display: flex;
  flex-direction: row;  
}

@media (min-width: 768px) {
  .conChido {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.con1 {
  overflow-y: hidden;
  min-width: 40px;
}
.conHi {
  min-width: 600px;
  display: flex; /* Use flexbox */
  flex-direction: row; /* Arrange children in a column */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

.contChatInput{
    display: flex;
  flex-direction: column;
  
  min-width: 200px;
  max-height: 100%;
}
.chatListCon{
  min-width: 200px;
 
}
 
.chatInputCon {
    position: relative; /* Set the container to relative positioning */
    display: flex;
    align-items: center; /* Align items vertically */
  }

/* Mobile devices (default) */
.bodyMindIn {
  position: absolute;
  bottom: 10px;
}

@media (max-width: 767px) {
  .bodyMindIn {
    position: absolute;
    bottom: 10px;
    min-width: 90%;  
    left: 20px;
  }
}

/* PC */
@media (min-width: 768px) {
  .bodyMindIn {
    position: absolute;
    bottom: 15px;
    min-width: 55%;
  }
}



.conlineSelec{
  border-radius: 104px;
 
}




.con1::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .con1::-webkit-scrollbar-track {
    background: #000000; /* Color of the scrollbar track */
  }
  
  .con1::-webkit-scrollbar-thumb {
    background: #000000; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Border radius of the scrollbar thumb */
  }
  
  .con1::-webkit-scrollbar-thumb:hover {
    background: #141414; /* Color of the scrollbar thumb on hover */
  }
  


  .hidden {
    display: none;
  }
  
  @media (max-width: 767px) {
    .conChido {
      flex-direction: column;
    }
    
    .con1 {
      min-width: 100%;
    }
    
    .contChatInput {
      width: 100%;
    }
  }
  

  .goBackButton{
    background-color: #5418ED;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 20px;
     
  }

  .successImage {
    width: 100px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 0 auto; /* Center horizontally */
    padding: 20px; /* Add padding around the image */
  }
  