/*
.posts-container-query-user {
    column-count: 3;
    column-gap: 0;
    width: 100%;
    background-color: #000000;
  }


.post-back-query {
  width: 100%;
  height: 200px;  
  object-fit: cover;
  border-radius: 10px;
     
  }

.post-profile-query {
    width: 75px;  
    height: 75px;
    border-radius: 8px;
    position: relative; 
    top: -55px;
    background-color: rgb(68, 182, 182);
  }

 *//* Container with a multi-column layout */
 /* Color for all text with 'colored-text' class */
.colored-text {
  color: #bcbcbc; /* You can change this color to anything you like */
}

.colored-text2 {
  color: #ffffff; /* You can change this color to anything you like */
}

.post-back-query {
  width: 100%;
  height: 100;  
  object-fit: cover;
  border-radius: 10px;
     
  }
/* Additional styles */
.posts32 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 12px;
  padding: 12px;
}

.post32 {
  background: #111;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: 0.2s;
}

.post32:hover { transform: translateY(-2px); }

.img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.name, .description {
  padding: 12px;
  font-size: 14px;
}

.info32 {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 12px;
}

.item span {
  font-weight: 600;
  margin-right: 4px;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.actions button {
  background: none;
  border: none;
  cursor: pointer;
}
