.listChatcon {
  height: 80%; /* Set height to 80% of its containing block */
  max-height: calc(100vh - 50px); /* Set maximum height to 80% of viewport height, subtracting any additional margins/borders */
  overflow-y: auto; /* This will display a scrollbar if content exceeds the maximum height */
}

.chatItem1 {
    display: flex;
    align-items: center;
    min-width: 145px;
    padding: 10px;
  }

  .chatItem1.selected {
    background-color: #0a0a0a;
  }
  

  .chatItem1:hover {
  background-color: #222;
  border-radius: 20px;
  }

  
  
  .initialsBadge {
    width: 80px; /* match the profile image size */
    height: 80px; /* match the profile image size */
    border-radius: 50%; /* makes the badge circular */
    background-color: rgb(0, 0, 0); /* example background color, change as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* text color */
    font-weight: bold; /* bold text for initials */
     /* space between badge and text */
  }
  
  .userName {
    font-weight: bold; /* bold text for the user's name */
    color: #ffffff;
    padding: 5PX;
    background-color: #101010;
    border-radius: 10PX;
    margin-right: 10PX;
    cursor: pointer;
  }
  
  .lastMessage {
    font-size: 0.9em; /* smaller text for the last message */
    color: #ccc; /* lighter text color for the last message */
  }
  

  .dot {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
  /* Adjust as needed */
  }
  
  .chatText{
   
  
  }
  
  .statusDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    /* Add more styles as needed */
  }
  