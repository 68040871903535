/*
.conProCh {
    width: 100%;   
    height: 100%;
    min-height:100vh;
    background-color: rgb(0, 0, 0);
  
}

@media (min-width: 600px) {
  .container {
    flex-direction: row;
  }
  .profile, .dashboard, .posts {
    flex: 1;
  }
}
*/

.conProCh {
 
  overflow: hidden;  
  background-color: black;
}


.sidenita {
  background-color: black;
}


.segundoCont {
 
  background-color: black;
  
}


 
@media only screen and (min-width: 768px) {
  .conProCh {
    display: flex;
    height: 100vh;
  }
}

    
  