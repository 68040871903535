/* Main container for the entire job selection section */
.custom-historial-container {
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
  height: 70%; /* Ensure the container adjusts */
}

/* Flex container for the header section with title and button */
.custom-container32 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Title styling */
.custom-title3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}

/* Styling for the continue button */
.custom-continue-button {
  padding: 10px 20px;
  background-color: #5418ED;
  color: white;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.custom-continue-button:hover {
  background-color: #DF18ED;
}

/* Masonry-style grid layout */
.custom-historial-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  grid-gap: 20px; /* Space between the grid items */
  padding: 0;
  list-style-type: none;
  max-height: 400px; /* Set maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  margin: 0;
}

/* Styling for each job item */
.custom-historial-item {
  background-color: #0b0b0b;
  color: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto; /* Adjust dynamically to content */
}

/* Change background when an item is selected */
.custom-historial-item.selected {
  background-color: #2782de;
  color: #fff;
  font-weight: bold;
}

/* Styling for when no jobs are found */
.custom-no-history {
  color: #888;
  font-style: italic;
}
