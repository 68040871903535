/*


 
.posts-container65-43 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;  
  background-color: rgb(110, 92, 92); 
 
  overflow-y: auto;
 
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
 
.content-wrapper-vertical {
  display: flex;
  flex-direction: column; 
  width: 100%;
  height: auto; 
}

 
.post-media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-shrink: 0; 
  position: relative;
  scroll-snap-align: start;
}

 

.post-media-wrapper video {
  width: 100vw;
  height: 100vh;
  object-fit: contain;  
  max-width: 100vw;
  max-height: 100vh;
}

.post-media-wrapper img {
  object-fit: contain;  
  max-width: 100vw;
  max-height: 100vh;
}

 
.react-confetti {
  z-index: 100;
}

 
.no-more-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: white;
}


.buttonContainer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  z-index: 10;
}

 
.minimalButton {
  background-color: rgba(255, 255, 255, 0.8); 
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.minimalButton:hover {
  background-color: white;
}


.post-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

 
.post-icon-text {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

 
@media only screen and (min-width: 768px) {
  .posts-container65-43 {
    flex-direction: row;  
  }

  .content-wrapper-vertical {
    width: 70vw;  
    height: 100vh;
  }

  .post-media-wrapper {
    width: 100%;
  }

  .right-modal-container {
    display: block;  
  }
}

 
@media only screen and (max-width: 768px) {
  .right-modal-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
 
  .post-icon-text {
    font-size: 16px;
  }

  .minimalButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}




*/
 
.posts-container65-43 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;  
  background-color: rgb(17, 17, 17); 
 
  overflow-y: auto;
 
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
 
.content-wrapper-vertical {
  display: flex;
  flex-direction: column; 
  width: 100%;
  height: auto; 
}

 
.post-media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-shrink: 0; 
  position: relative;
  scroll-snap-align: start;
}

 

.post-media-wrapper video {
  width: 100vw;
  height: 100vh;
  object-fit: contain;  
  max-width: 100vw;
  max-height: 100vh;
}

.post-media-wrapper img {
  object-fit: contain;  
  max-width: 100vw;
  max-height: 100vh;
}

 
.react-confetti {
  z-index: 100;
}

 
.no-more-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: white;
}


.buttonContainer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  z-index: 10;
}

 
.minimalButton {
  background-color: rgba(255, 255, 255, 0.8); 
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.minimalButton:hover {
  background-color: white;
}


.post-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

 
.post-icon-text {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

 
@media only screen and (min-width: 768px) {
  .posts-container65-43 {
    flex-direction: row;  
  }

  .content-wrapper-vertical {
    width: 70vw;  
    height: 100vh;
  }

  .post-media-wrapper {
    width: 100%;
  }

  .right-modal-container {
    display: block;  
  }
}

 
@media only screen and (max-width: 768px) {
  .right-modal-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
 
  .post-icon-text {
    font-size: 16px;
  }

  .minimalButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.post-icon-text {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.show-modal-button {
  position: absolute;
  top: 20px; /* You can adjust this value if needed */
  right: 10px;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



/*


.posts-container65-43 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;  
  background-color: black;  
  
   
  scroll-snap-type: y mandatory;  
  scroll-behavior: smooth;  
}

 
.content-wrapper-vertical {
  display: flex;
  flex-direction: column;  
  width: 100%;
  height: auto; 
}

 
.post-media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-shrink: 0;  
  position: relative;
  
  
  scroll-snap-align: start;
}


.post-media-wrapper video {
  width: 100vw;
  height: 100vh;
  object-fit: contain;  
  max-width: 100vw;
  max-height: 100vh;
}

.post-media-wrapper img {
  object-fit: contain;  
  max-width: 100vw;
  max-height: 100vh;
}

 
.react-confetti {
  z-index: 100;
}

 
.no-more-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: white;
}

 
.buttonContainer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  z-index: 10;
}

 
.minimalButton {
  background-color: rgba(255, 255, 255, 0.8);  
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.minimalButton:hover {
  background-color: white;
}

 
.post-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

 
.post-icon-text {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  
  .post-icon-text {
    font-size: 16px;
  }

  .minimalButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}

*/