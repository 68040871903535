@import '~leaflet/dist/leaflet.css';
 
body{
  width: 100%;
}
 
*{
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
   
  margin: 0;
  padding: 0;
}



/* Other global styles for your app might follow here */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ... other styles ... */



