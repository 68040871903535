/*
.cont-main-query{
    display: flex;
    flex-direction: column; 
    align-items: center;  
    justify-content: center;  
    text-align: center; 
    border-radius: 11px;
    padding: 10px;
    margin-top: 10px;
  }
   

.con-buscador-query {
    display: flex;
    align-items: center;
    background-color: #111111;
    border-radius: 30px;
    padding: 5px 15px;
    width: 100%;  
   height: 60px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4)
    
  }


.search-input-query {
    width: 100%;  
    background: transparent;
    border: none;
    outline: none;
    color: rgb(228, 228, 228);
    font-size: 16px;
    padding-left: 20px;
  }
    */
    .cont-main-query {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 11px;
      padding: 10px;
      margin-top: 10px;
      width: 100%;
  }
  
  .con-buscador-query {
      display: flex;
      align-items: center;
      background-color: #111111;
      border-radius: 20px;
      padding: 5px 15px;
      width: 100%;
      height: 60px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      position: relative;
  }
  
  /* Remove the top border-radius when suggestions are visible */
  .con-buscador-query.suggestions-visible {
      border-radius: 20px 20px 0 0;
  }
  
  .search-input-query {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      color: rgb(224, 224, 224);
      font-size: 16px;
      padding-left: 20px;
      border-radius: 0;
  }
  
  .historial-button {
      background-color: transparent;
      border: none;
      color: #fff;
      cursor: pointer;
      border-radius: 0;
  }
  
  /* Suggestions List */
  .suggestions-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      background-color: rgb(65, 64, 64);
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      z-index: 1000;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 20px 20px; /* Keep bottom border-radius */
  }
  
  .suggestion-item {
      padding: 10px;
      cursor: pointer;
  }
  
  .suggestion-item:hover {
      background-color: #f0f0f0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
      .con-buscador-query {
          padding: 5px 10px;
          height: 50px;
      }
  
      .search-input-query {
          font-size: 14px;
          padding-left: 10px;
      }
  
      .suggestions-list {
          top: 50px;
      }
  
      .suggestion-item {
          padding: 8px;
      }
  }
  
  @media (max-width: 480px) {
      .con-buscador-query {
          height: 45px;
      }
  
      .search-input-query {
          font-size: 12px;
          padding-left: 8px;
      }
  
      .suggestions-list {
          top: 45px;
      }
  
      .suggestion-item {
          padding: 6px;
      }
  }
  