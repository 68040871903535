.contenedorQuesomo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align children at the top */
    flex: 1;
    background-color: black;
    padding: 20px;
    color: white;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100vh; /* Limit the height to the viewport height */
    box-sizing: border-box; /* Ensure padding is included in the height calculation */
}


.center-align {
    text-align: center;
}

.textTi {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.llama {
    color: #c1c1c1;
    font-size: 10px;
    font-weight: normal;
    text-align: center;
    margin: 20px 10px;
    padding: 20px;
}

.bold-text {
    font-size: 10px;
    font-weight: bold;
    color: #c0c0c0;
}

.supTitulo2 {
    color: #cccccc;
    font-size: 15px;
    font-weight: normal;
    text-align: justify; /* Justify text for better readability */
    padding-left: 10px; /* Left padding */
    padding-right: 10px;
    margin: 10px 0;
    line-height: 1.5; /* Improved line spacing */
}

.ttCotratoD {
    font-weight: bold; 
    font-size: 18px; 
    color: #fcfcfc;
    padding-left: 22px;
    margin-top: 20px; /* Space above each section title */
}

.bottonMenu1 {
    background: none;
    border: 3px solid #ffffff;
    max-width: 200px;
    border-radius: 5px;
    font-size: 16px;
    color: rgb(252, 252, 252);
    padding: 10px 20px;
    cursor: pointer;
    margin: 20px auto; /* Center button horizontally */
    display: block;
}

.conCONTACT {
    margin-top: 10px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; /* Prevent shrinking */
}
.ttt0 {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 0; /* Add some padding to each section */
}

.list-item{
    color: #919191;
}
.list-tt{
    color: #e8e8e8;
}