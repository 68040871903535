/* Main Finder Container */
.cont-main-finder7 {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    text-align: center;
    background-color: #CDED17;
    border-radius: 11px;
    padding: 5px;
    margin-top: 11px;
    position: relative; /* To position the suggestions dropdown correctly */
}

/* Container for the search input */
.con-buscador-query7 {
    display: flex;
    align-items: center;
    background-color: #111111;
    border-radius: 30px;
    padding: 5px 15px;
    width: 100%; /* Or any specific width you want */
    height: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    position: relative; /* Ensure the input is positioned relative to its parent */
}

.con-buscador-query7.suggestions-visible {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Styling for the search input field */
.search-input-query7 {
    width: 100%;  /* Take up remaining space */
    background: transparent;
    border: none;
    outline: none;
    color: rgb(228, 228, 228);
    font-size: 16px;
    padding-left: 20px;
}

/* Styling for the search icon */
.iconSearch7 {
    margin-right: 10px;
    color: #888;
}

/* Styling for the autocomplete suggestions dropdown */
 

.autocomplete-suggestions {
    position: absolute;
    top: 100%; /* Position below the input field */
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto; /* Scroll if there are too many suggestions */
    background-color: #171717;
    border-radius: 0 0 20px 20px;
    z-index: 1000;
}

/* Styling for each suggestion item */
.suggestion-item {
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
}

/* Highlight the suggestion on hover */
.suggestion-item:hover {
    background-color: #151515;
}
