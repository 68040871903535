.m54 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.profile-info, .company-info {
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between image and text */
  margin-bottom: 10px; /* Space between profile info and other elements */
}

.im79 {
  width: 70px; /* Set the size of the image */
  height: 70px;
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image fits well within the circular frame */
}

.ttdefault43 {
  color: rgb(255, 255, 255);
}

.tt-name {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
