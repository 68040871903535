/* Container for posts */
.container345 {
  display: flex;
  flex-wrap: wrap; /* Allows columns to wrap to the next line */
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.c345 {
  max-height: 80vh; /* Set a maximum height for the content */
  overflow-y: auto; /* Enables vertical scrolling if content exceeds the max height */
  padding-right: 15px; /* Optional: To avoid content hiding behind the scrollbar */
}

/* Column styling */
.column345 {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Specific styling for left and right columns */
.left-column345 {
  background-color: #000000;
}

.right-column345 {
  background-color: #5418ED;
}

/* Responsive Design */

/* For mobile devices (1 column) */
@media (max-width: 767px) {
  .column345 {
    flex: 100%; /* Full width */
  }
}

/* For tablets (2 columns) */
@media (min-width: 768px) and (max-width: 1024px) {
  .column345 {
    flex: 50%; /* Two columns */
  }
}

/* For desktops (2 columns) */
@media (min-width: 1025px) {
  .column345 {
    flex: 50%; /* Two columns */
  }
}



/* Image post container */
.con-image-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

/* Input row container */
.rowContainerInputs {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0 0;
}

/* Submit button styling */
.buttonSudmitAdd {
  background-color: #CDED17 ;
  color: #000;
  width: 80px;
  padding: 12px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.buttonSudmitAdd:hover {
  background-color: #e0e000;
}

/* Cancel button styling */
.buttonCancel {
  margin: 20px 10px 20px 20px;
  background-color: #000;
  color: #e6e6e6;
  width: 100px;
  padding: 12px;
  border: 1px solid #464646;
  border-radius: 50px;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.9;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.buttonCancel:hover {
  background-color: #333;
  color: #fff;
}

/* Image upload container */
.image-upload-containerAdd {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  margin: 3vh 0;
}

/* Box decoration */
.box-decoration {
  border-radius: 5px;
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Input styling */
.entrada {
  color: #ffffff;
  width: 100%;
  height: 55px;
  padding: 12px 20px;
  margin-left: 10px;
  border-radius: 20px;
  background-color: #393939;
  border: none;
  box-sizing: border-box;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Employment info container */
.employment-info-container {
  flex: 1;
  padding: 25px;
  background-color: #282828;
  border-radius: 10px;
}


.contenedorTexto{
  margin-bottom: 10px;
 
  padding-left: 30px;
}
/* Text styling */
.textoCampos, .textoCamposMini {
  color: #9A18ED ;
  margin: 10px 0;
  font-size: 14px;
}

.optionTex{
  color: #f7f7f7 ;
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
}

.textoCampos22{
  color: #e7e7e7 ;

  font-size: 14px;
  font-weight: bold;
}

.textoCamposMini {
  color: #5418ED  ;
  font-size: 10px;
  margin-left: 5px;
}

/* Row container */
.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Muted text */
.text-muted {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

/* Section title */
.supTitulo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Placeholder for post with no image */
.con-sin-image-user-post {
  margin-top: 20px;
  width: 350px;
  height: 220px;
  background-color: #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  animation: resizeAnimation 15s ease-in-out infinite;
}

/* Animation for post placeholder */
@keyframes resizeAnimation {
  0%, 100% {
    width: 350px;
    height: 220px;
  }
  50% {
    width: 200px;
    height: 350px;
  }
}

@media (max-width: 768px) {
  @keyframes resizeAnimation {
    0%, 100% {
      width: 350px;
      height: 350px;
    }
    50% {
      width: 250px;
      height: 350px;
    }
  }
}

/* Image post styling */
.image-post-add {
  margin-top: 15px;
  width: 50%;
  height: 50%;
  border-radius: 20px;
  object-fit: cover;
  padding: 20px;
  border: 2px solid #fff;
}

/* Subtitle text */
.textoSupTituloadd {
  margin-top: 10px;
  color: #282828;
  font-size: 12px;
}

/* Map container */
.MapContainer {
  height: 200px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
}

/* Nearby data list */
.NearbyDataList {
  padding: 10px;
  border: 1px solid #151515;
  border-radius: 8px;
  max-width: 400px;
  margin: auto;
}

/* Nearby data item */
.NearbyDataItem {
  margin-bottom: 10px;
}


