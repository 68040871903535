 
  .video-background {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    object-fit: cover;
  }


  .overlay button {
    background: none;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    padding: 10px 60px;
    cursor: pointer;
  }
  
  .overlay button:hover {
    background: white;
    color: black;
    transition: all 0.3s;
  }
  

  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }

  .headerBlock__info {
    display: flex;
    flex-direction: column;
    height: 360px;
     
  }
  
  .headerBlock__infoText {
    text-align: center;
    flex: 1;
  }
  
  .headerBlock__infoText > h1 {
    color: #393c41;
    font-weight: 500;
    font-size: 38px;
  }
  
  .headerBlock__infoText > h4 {
    font-weight: 400;
    font-size: 15px;
    color: #5c5e62;
  }
  
  .headerBlock__infoText > h4 >  {
    padding-bottom: 4px;
    padding-top: 15px;
    border-bottom: 1px solid black;
    color: #ffffff;
    cursor: pointer;
  }
  
  .headerBlock__infoText > h4 >   {
    border-bottom-width: 2px;
    color: black;
  }
  
  .headerBlock__actions {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    margin-top: 330px;
    gap: 10px;
     padding: 10px;
  }
  


  .headerBlock__actions > button {
    cursor: pointer;
    border: none;
    width: 260px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: 600;
    outline: none;
     
  }
  
  .headerBlock__buttonPrimary {
    background-color: #393c41;
    color: white;
    opacity: 0.9;
  }
  
  .headerBlock__buttonSecondary {
    background-color: white;
    color: black;
    opacity: 0.8;
  }
  .titulo1{
    color:#fff;
    font-size: 35px;
    font-weight: normal;
  }
  .titulo2{
    color:#e6e5e5;
    font-size: 15px;
    font-weight: normal;
    margin-top: 15px;
    text-decoration: underline  ;
  }

  .titulo3{
    color: #fff;
  }