 /*
 

  .post12-43 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    padding: 20px;
    
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
  }
  
  .post-details-43 {
    display: flex;
    flex-direction: column;
    background-color: #0f0f0f;
    padding: 20px;
    border-radius: 20px;
    color: white;
    overflow: auto;
    
    min-width: 300px;
    max-height: 100VH;
  
    ::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .post-column {
    display: flex;
    flex-direction: column;
    
  }
  
  .post-icon-text {
    display: flex;
    align-items: center;
    margin: 2px;
    background-color: #070707;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .post-des2-43 {
    margin-left: 10px;
    font-size: 16px;
    color: #ffffff;
  }
  
  .post-image-profile-43 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .userSpa-43 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    color: white;
  }
  
  .post-column-user-43 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .post-likes-43 {
    font-size: 16px;
    color: white;
    font-weight: bold;
     
    display: flex;
    align-items: center;
    justify-content: center;  
    height: 100%;
    border-radius: 20px;
    padding: 10px;
  }
  
  .con-comen{
    margin-top: 20px;
  }
  
  @media (min-width: 769px) {
    .post12-43 {
      max-width: 100%;
      max-height: 100%; 
    }
  
    .post-details-43 {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .post-column {
      flex: 1;
      min-width: 45%;
      max-width: 45%;
    }
  
   
  }
  
  @media (max-width: 768px) {
    .post-details-43 {
      overflow: visible;
    }
  
    .post-column {
      width: 100%;
    }
  
    .post-des2-43 {
      font-size: 16px;
      text-align: center;
    }
  
    .post-column-user-43 {
      flex-wrap: wrap;
    }
  
    .post-likes-43 {
      margin-bottom: 10px;
    }
  }
  .action-row {
    display: flex;
    gap: 10px;  
    justify-content: center; 
    align-items: center;  
  }
   
  .full-screen-modal1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);  
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal-content1 {
    background-color: #1e1e1e;  
    border-radius: 20px;
    padding: 40px 30px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);  
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #f5f5f5;  
    margin-bottom: 16px;
  }
  
  .modal-message {
    font-size: 16px;
    color: #cfcfcf;  
    margin-bottom: 24px;
  }
  
  .sign-up-button1 {
    background-color: #ffffff;  
    color: #0c0c0c;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 8px 0;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .sign-up-button1:hover {
    background-color: #c6f312;  
    font-weight: bold;
    color: #0c0c0c;
  }
  
  .close-button1 {
    background-color: #333333;  
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 14px;
    cursor: pointer;
    margin: 8px 0;
    transition: background-color 0.3s ease;
  }
  
  .close-button1:hover {
    background-color: #444444; 
  }
  
  
  */
 
  
  .post12-43 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    padding: 20px;
    
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
  }
  
  .post-details-43 {
    display: flex;
    flex-direction: column;
    background-color: #0f0f0f;
    padding: 20px;
    border-radius: 20px;
    color: rgb(223, 223, 223);
    overflow: auto; /* Enable scrolling */
    max-height: 100vh; /* Limit height to viewport */
    position: relative;
    
    ::-webkit-scrollbar {
      display: none; /* Hide scrollbar */
    }
    
    scrollbar-width: none; /* Firefox hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer hide scrollbar */
  }

  @media (max-width: 768px) {
    .post-details-43 {
      max-height: 90vh; /* Reduce max height for mobile */
      overflow-y: auto; /* Enable vertical scroll on mobile */
    }
  }
  
  
  .post-column {
    display: flex;
    flex-direction: column;
  }
  
  .post-icon-text {
    display: flex;
    align-items: center;
    margin: 2px;
    background-color: #070707;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .post-des2-43 {
    margin-left: 10px;
    font-size: 16px;
    color: #ffffff;
  }
  
  .post-image-profile-43 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .userSpa-43 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    color: white;
  }
  
  .post-column-user-43 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .post-likes-43 {
    font-size: 16px;
    color: white;
    font-weight: bold;
     
    display: flex;
    align-items: center;
    justify-content: center;  
    height: 100%;
    border-radius: 20px;
    padding: 10px;
  }
  
  .con-comen{
    margin-top: 20px;
  }
  
  @media (min-width: 769px) {
    .post12-43 {
      max-width: 100%;
      max-height: 100%; 
    }
  
    .post-details-43 {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .post-column {
      flex: 1;
      min-width: 45%;
      max-width: 45%;
    }
  
   
  }
  
  @media (max-width: 768px) {
    .post-details-43 {
      overflow: visible;
    }
  
    .post-column {
      width: 100%;
    }
  
    .post-des2-43 {
      font-size: 16px;
      text-align: center;
    }
  
    .post-column-user-43 {
      flex-wrap: wrap;
    }
  
    .post-likes-43 {
      margin-bottom: 10px;
    }
  }
  .action-row {
    display: flex;
    gap: 10px;  
    justify-content: center; 
    align-items: center;  
  }
   
  .full-screen-modal1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);  
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal-content1 {
    background-color: #1e1e1e;  
    border-radius: 20px;
    padding: 40px 30px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);  
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #f5f5f5;  
    margin-bottom: 16px;
  }
  
  .modal-message {
    font-size: 16px;
    color: #cfcfcf;  
    margin-bottom: 24px;
  }
  
  .sign-up-button1 {
    background-color: #ffffff;  
    color: #0c0c0c;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 8px 0;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .sign-up-button1:hover {
    background-color: #c6f312;  
    font-weight: bold;
    color: #0c0c0c;
  }
  
  .close-button1 {
    background-color: #333333;  
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 14px;
    cursor: pointer;
    margin: 8px 0;
    transition: background-color 0.3s ease;
  }
  
  .close-button1:hover {
    background-color: #444444; 
  }
  