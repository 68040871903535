.aesthetic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Helvetica Neue', sans-serif;
    color: #fff;
    background-color: #101010; /* Dark background for contrast */
    border-radius: 12px;
    padding: 5px;
    max-width: 200px;
    margin: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    
  }
  
  .follow-block {
    text-align: center;
    padding: 5px;
  }
  
  .count {
    font-size: 18px;
    font-weight: bold;
    color: #deedfe; /* Modern blue accent color */
  }
  
  .follow-text {
    font-size: 12px;
    color: #8b949e; /* Subtle grey for text */
  }
  
  .follow-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .follow-block:not(:last-child) {
    border-right: 1px solid #30363d; /* Subtle divider between blocks */
    padding-right: 20px;
  }
  
  
  
  .follow-container .follow-block span {
    display: block;
  }
  