 


.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This will take the full height of the viewport */
  /* Add other styles for the container as needed */
}

.content {
  text-align: center;
  /* Add other styles for the content as needed */
}

.asterisk {
  color: red; /* Example color for the asterisk */
  font-weight: bold;
  margin-left: 2px; /* Adjust as needed */
  /* Add other styles for the asterisk as needed */
}

.texREGISTER{
 color: rgb(255, 255, 255);
}

.texREGISTER:hover {
  color: rgb(0, 0, 0);
 }

/* Existing styles for .sidenav__more and .glow-on-hover1 */
