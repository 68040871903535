@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.form-element {
  animation: fadeIn 1s ease-out;
}
 
 
.login343 {
  background-color: black;
  height: 100vh;
 
 
 
}
