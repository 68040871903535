.chatList1 {
  min-height: 50vh;
  height: 0vh; /* 50% of the viewport height */
  overflow-y: auto;
  
  /* Add scrollbar when content exceeds container height */
}

/* Media query for tablets (including iPads) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .chatList1 {
    min-height: 75vh; /* Slightly larger min-height for tablets */
    height: 0vh; /* Slightly larger height for tablets */
  }
}

/* Media query for large screens */
@media only screen and (min-width: 1025px) {
  .chatList1 {
    min-height: 75vh; /* Larger min-height for large screens */
    height: 0vh; /* Larger height for large screens */
  }
}
  
  /* Style for the scrollbar */
  .chatList1::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */

  }
  
  /* Track */
  .chatList1::-webkit-scrollbar-track {
    background: #000000; /* Color of the track */

     
  }
  
  /* Handle */
  .chatList1::-webkit-scrollbar-thumb {
    background: #000000; /* Color of the scrollbar handle */
  }
  
  /* Handle on hover */
  .chatList1::-webkit-scrollbar-thumb:hover {
    background: #000000; /* Color of the scrollbar handle on hover */
   
  }
  