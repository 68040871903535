/* Container styling for the button */
.cont54{
    margin-top: 10px;
    padding: 10px;
}

.save-button {
    background-color: transparent;
    border: 2px solid #616161;
    color: #dadada;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Adds space between the icon and text */
    font-family: 'Arial', sans-serif;
  }
  
  /* Icon-only button: remove text from button if needed */
  .save-button svg {
    margin: 0; /* Center the icon perfectly */
  }
  
  /* Saved state styling */
  .save-button.saved {
    border-color: #c6f312; /* Change color for saved state */
    color: #c6f312; /* Change color when saved */
  }
  
  /* Hover effect for the button */
  .save-button:hover {
    background-color: #fff;
    color: #070707;
  }
  
  /* Icon hover effect: enlarge slightly when hovered */
  .save-button svg {
    transition: transform 0.3s ease;
  }
  
  .save-button:hover svg {
    transform: scale(1.1); /* Slightly enlarge the icon */
  }
  
  /* Make the button appear more modern and subtle */
  .save-button {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Remove button outline when clicked */
  .save-button:focus {
    outline: none;
  }
  
  /* Media queries to ensure responsiveness */
  @media (max-width: 768px) {
    .save-button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  