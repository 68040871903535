 


.posts-container65 {
 
  width: 100%;
  background-color: #000000;
  margin-top: 10px;

  row-gap: 10px; /* Space between rows */
  column-gap: 4px; 
  column-count: 2;
}

.post12 {
  display: flex;
  flex-direction: column;
  background: #000000;
  height: min-content; /* Adjust height based on content */
  border-radius: 10px;
  overflow: hidden; 
  border: 1px solid rgb(21, 21, 21);
  margin: 5px 0px;
  width: 100%;
}


.post12:hover {
  background: #1f1f1f;
}



@media (min-width: 480px) {
  .posts-container65 {
    column-count: 3; /* 1 column for mobile screens */
  
  }
  
}

/* For tablets or iPads */
@media (min-width: 768px) {
  .posts-container65 {
    column-count: 2; /* 2 columns for iPads */
  }
  
}

/* For desktops */
@media (min-width: 1024px) {
  .posts-container65 {
    column-count: 3; /* 3 columns for desktop screens */
  }
 
  
}

@media (min-width: 1400px) {
  .posts-container65 {
    column-count: 4; /* 4 columns for screens 1200px and larger */
  }
}

@media (min-width: 1500px) {
  .posts-container65 {
    column-count: 5; /* 5 columns for screens 1400px and larger */
  }
}

@media (min-width: 1600px) {
  .posts-container65 {
    column-count: 6; /* 5 columns for screens 1400px and larger */
  }
}


@keyframes zoomIn {
    0% {
      font-size: 1em;   /* start font size */
      opacity: 0;       /* start fully transparent */
    }
    100% {
      font-size: 20px; /* end font size */
      opacity: 1;       /* fully visible */
    }
  }
  
  /* Apply the animation to the .texpaga class */
  .texpaga {
    text-align: center;
    animation: zoomIn 2s forwards; /* apply the zoomIn animation over a duration of 2 seconds */
  }
 
/*====================BUTTON */
 .neonButton {
    background-color: black;
    border: 1px solid white;
    color: white;
    font-size: 20px;
    padding: 5px 15px;
    position: relative;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    transition: color 0.3s ease;
    border-radius: 100px;
    font-weight: bold;
    
  }
  
  .neonButton:hover {
    color: black;
  }
  /*border-image: linear-gradient(45deg, #ff0000, #ff7300, #ff00ab, #8a00ff, #442aff);*/
  /*background: linear-gradient(45deg, #ff0000, #ff7300, #ff00ab, #8a00ff, #442aff);*/
  .neonButton:before,
  .neonButton:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(45deg, #ff0000, #ff7300, #ff00ab, #8a00ff, #442aff);
    background-size: 400% 400%;
    border: 2px solid;
    
    border-image-slice: 1;
    animation: gradient 15s infinite;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    25% {
      background-position: 100% 0;
    }
    50% {
      background-position: 100% 100%;
    }
    75% {
      background-position: 0 100%;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .navigatePerfilButton{
    border-radius: 10px;
    background-color: transparent;
    border: none;
    display: flex;           /* Enables flexbox */
    justify-content: center; /* Centers content horizontally */
    align-items: center;     /* Centers content vertically */
    flex-direction: column;
  }

.texANOW{
  font-weight: bold;
  color:#000000;
  font-size: 13PX;
}

.tMessage{
  font-weight: bold;
  color:#cacaca;
}

.tMessage:hover {
  color: #000;
}



.conButtonApply{
  padding: 10px;
  width: 20;
}

  .glow-on-hover {
    width: 100px;
    height: 40px;
    border: none;
    outline: none;
    color: #f7f6f6;
    background: #CDED17;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 50px;
    margin: 10px;
  
 
  }
  
  .glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 50px;
  }
  
  .glow-on-hover:active {
    color: #000;
  }
  
  .glow-on-hover:active:after {
    background: transparent;
  }
  
  .glow-on-hover:hover:before {
    opacity: 1;
  }
  
  @keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
  }
  
  
  .bMessage {
    width: 80px;
    height: 30px;
    border: 1px solid #252525; /* Adding border */
    outline: none;
    color: #252525;
    background: #000000;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 50px;
  }
  

  .bMessage:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 50px;
  }
  
  .bMessage:active {
    color: #000;
  }
  
  .bMessage:active:after {
    background: transparent;
  }
  
  .bMessage:hover:before {
    opacity: 1;
  }


  .clean-button {
    padding: 1px 1px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px none #ddd;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  /*#999ca0;*/
  .clean-button:hover,
  .clean-button:focus {
    color: #fff;
    background-color: #999ca0;;
    border-color: #007bff;
  }
.imgPostUser{
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
 }
 
  
  .post-image65 {
    width: 100%; /* Makes the image take the full width of the container */
    height: auto;
    border-radius: 8px;
     /* Adds space between the image and the title */
  }
  
  .post-info {
    flex: 1;
    text-align: center; /* Centers the text inside the post-info */
  }
  
  .post-title {
    color: #e6e6e6;
    line-height: 15px; 
    overflow: hidden;
    padding: 10PX;
    text-align: start; 
  }
  
  
  .post-description1{
    color: #f5f5f5;
     
  }
  
 
  
  .post-likes {
    color: #b8b8b8;
    font-weight: bold;
    font-size: 13PX;
  }

  .conCity{
    margin-right: 20px;
  }
  
  .post-button {
    padding: 5px 1px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .post-button:hover {
    background: #0056b3;
  }

  
  
  @media (max-width: 768px) {
    .posts-container65 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .posts-container65 {
      grid-template-columns: 1fr;
    }
  }


  .post-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .post-column {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align items vertically */
    align-items: flex-start; /* Align items horizontally */
    flex: 1; /* Each column takes up an equal amount of space */
 
     
  }

  .post-column1{
    border-radius: 10px;
    padding: 10px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
 
  .post-column:first-child {
    margin-right: 20px;
  }
  

  .aaa{
    padding-left: 20px;
  }
 
  .userSpa{
    
     font-size: 13px;
     margin-left: 10px;
     font-weight: bold;
  }

  .aaaNI{
    margin: 10px;
  }

  .CCS4{
    display: flex; /* Assuming CCS4 is a flex container */
    align-items: center; /* Align items vertically in the center */
  }

  .aaa55m {
    display: flex;
    flex-direction: column;  
    padding: 10px;
  }
  
 
