 
.containerSIDENA {
  flex: 1;
  background-color: #000;
  height: 100%; /* Full height or change as needed */
  z-index: 10; 
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  text-align: center; 
  padding-right: 5px; /* Add padding to the right */
  margin-right:5px;
  
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .containerSIDENA {
    /* You can adjust right padding/margin here for this specific media query */
    padding-right: 10px; 
    margin-right: 10px; 
  }
}


 

/* Styles for mobile screens (< 768px) */
@media screen and (max-width: 768px) {
  .containerSIDENA {
    position: fixed; /* Ensure the container is fixed */
    bottom: 0; /* Position at the bottom */
    top: auto; /* Override any top value */
    width: 100%; /* Full width */
    height: auto; /* Auto height or change as needed */
   /* Minimum width */
  }
}
  
.containerBuscador{
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  text-align: center; 
  background-color: #000000;
  width: 100%; 
  margin-left: 5px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid rgb(21, 21, 21);
}
 
.containerB {
  background-color: #000000;
  display: flex;
  flex-direction: row; /* Arrange children in rows */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  width: 100%; 
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  border: 1px solid rgb(21, 21, 21);
}

.containerC {
  background-color: #000000;
  flex-direction: row;   
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid rgb(21, 21, 21);
  margin-left: 5px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .containerC {
    display: none;
  }
}



.containerD {
  background-color: #000000;
  display: flex;
  flex-direction: row; /* Arrange children in rows */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid rgb(21, 21, 21);
  margin-left: 5px;
}


.buscador {
  display: flex;
  align-items: center;
  background-color: #111111;
  border-radius: 30px;
  padding: 5px 15px;
  width: 100%; /* Or any specific width you want */
 
}

.search-icon {
  fill: rgb(234, 234, 234);
  font-size: 20px;  
}

 

.iconSearch{
  fill: rgb(47, 47, 47);
  margin-left: 20;
 font-size: 20px; 

}

 
.search-input::placeholder {
  color: #929292; /* Change to your desired color */
}

 

.search-input {
  width: 100%; /* Take up remaining space */
  background: transparent;
  border: none;
  outline: none;
  color: rgb(77, 77, 77);
  font-size: 16px;
}

.search-input::placeholder {
  color: rgba(78, 78, 78, 0.75); /* Lighter white color for placeholder text */
}

/* This is to ensure that the input doesn't have the white outline when focused */
.search-input:focus {
  border: none;
  outline: none;
}

.buttlogo{
 
  display: flex;
  align-items: center; /* This will vertically center your icon */
  justify-content: center; /* This will horizontally center your icon */
  /* Add any additional styling you need for the button here */
  height: 50px; /* Example height, adjust as needed */
  width: 50px; /* Example width, adjust as needed */
  border: none; /* Removes the border */
  background-color: transparent; /* Example background, adjust as needed */
  cursor: pointer; 
 
}

.buttlogoICON{
  display: flex;
  align-items: center; /* This will vertically center your icon */
  justify-content: center; /* This will horizontally center your icon */
  /* Add any additional styling you need for the button here */
  border: none; /* Removes the border */
  background-color: transparent; /* Example background, adjust as needed */
  cursor: pointer;  
}

.logo{
  height:45px; /* Desired height */
  width: 45px; /* Desired width */
 
}

.userImg{
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Set to 50% for a circular shape */
  object-fit: cover; /* Ensures the image covers the entire container without stretching */
 
}


.glow-on-hover1 {
  display: flex;  
  justify-content: center;  
  align-items: center;
  margin: 10px;
  width: 150px;
  height: 50px;
  border: none; 
  outline: none;
  border: 2px solid #ffffff; /* Add border */
  background: #000000;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px;
}

.glow-on-hover1:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 50px;
}

.glow-on-hover1:active {
  color: #000;
}

.glow-on-hover1:active:after {
  background: transparent;
}

.glow-on-hover1:hover:before {
  opacity: 1;
}

.texANOW12{
  font-size: 15px;
  font-weight: bold;
  color:#ffffff;
  padding: 5px;
}


.glow-on-hoverPost {
  display: flex;  
  justify-content: center;  
  align-items: center;
  margin: 10px;
  border: none; 
  outline: none;
  border: 2px solid #ffffff; /* Add border */
  background: #000000;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px;
 
}


.glow-on-hoverPost:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 50px;
  
}

.glow-on-hoverPost:active {
  color: #000;
}

.glow-on-hoverPost:active:after {
  background: transparent;
}

.glow-on-hoverPost:hover:before {
  opacity: 1;
}

.no-image-44 {
  width: 40PX;
  height: 40PX;
 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30PX;
}