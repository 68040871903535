/*


.backGraudCompany {
  background-color: black;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
}

.containerFormCompany {
  background-color: #000000;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.progress-bar-container {
  width: 100%;
  margin-bottom: 20px;
}

.progressSet {
  height: 25px;
  background: linear-gradient(90deg, #9A18ED, #ED18B1);
  text-align: center;
  line-height: 25px;
  color: white;
  border-radius: 25px;
  transition: width 0.2s ease;
}

.form-layout {
  display: flex;
  justify-content: space-between;
  gap: 30px;  
}

.conteCompany {
  flex: 1;  
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputCompañi {
  width: 100%;
  font-weight: bold;
  height: 60px;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 26px;
  background-color: #1e1e1e;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

.inputCompañi:hover {
  box-shadow: 0px 0px 10px #ffffff;
}

.inputCompañi::placeholder {
  color: rgb(255, 255, 255);
}

 
.profile-container {
  flex: 1;  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-image-container1{
  width:60%;
  margin-bottom: 20px;
  border-radius: 30px;
}
.profile-image-container {
  margin-bottom: 20px;
}

.background-image-container1:hover,
.profile-image-container:hover {
  box-shadow: 0px 0px 20px #5418ED;
}

.background-image {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover;
  padding: 20px;
  border: 2px solid #ffffff;
}

.no-image-back {
  width: 100%;
 
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 3px solid #fff;
}

.profile-image-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 3px solid #fff;
  cursor: pointer;
  margin-bottom: 20px;
}

.no-image-profile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.no-image-profile:hover {
  box-shadow: 0px 0px 10px #ffffff;
}

 
.user-info {
  text-align: center;
  margin-top: 20px;
}

.nameCompany {
  color: #f7f7f7;
  font-size: 1.2em;
  font-weight: bold;
}

.detallesCompany {
  margin-top: 10px;
  color: #d4d3d3;
  font-size: 1.2em;
}

 
.conButtonApply1 {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.glow-on-hover:hover {
  background-color: #ED18B1;
  box-shadow: 0px 0px 30px #DF18ED;
}

 

 
@media (max-width: 768px) {
  .form-layout {
    flex-direction: column;  
  }

  .inputCompañi,
  .profile-container {
    width: 100%;  
  }

  

  .no-image-back {
    height: 150px;
  }

  .profile-image-container {
    width: 80px;
    height: 80px;
  }

  .nameCompany,
  .detallesCompany {
    font-size: 1em;
  }
}

.icon79{
  font-size: 30px; 
  color: #ffffff; 
 
}

.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
  
}

.input-with-icon .icon {
  position: absolute;
  left: 15px;  
  color: #fff;
  font-size: 20px;
}

.input-with-icon .inputCompañi {
  padding-left: 45px; 
}


.b798 {
  width: 200px;
  height: 60px;
  border: none;
  outline: none;
  color: #f7f6f6;
  background: #CDED17;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px;
  margin: 10px;
}

.t768{
  font-size: 30px;
  font-weight: bold;
  color: #000;
}

.b798:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 50px;
}

.b798:active {
  color: #000;
}

.b798r:active:after {
  background: transparent;
}

.b798:hover:before {
  opacity: 1;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}



*/


.backGraudCompany {
  background-color: black;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
}

.containerFormCompany {
  background-color: #000000;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.progress-bar-container {
  width: 100%;
  margin-bottom: 20px;
}

.progressSet {
  height: 25px;
  background: linear-gradient(90deg, #9A18ED, #ED18B1);
  text-align: center;
  line-height: 25px;
  color: white;
  border-radius: 25px;
  transition: width 0.2s ease;
  font-weight: bold;
}

.form-layout {
  display: flex;
  justify-content: space-between;
  gap: 30px; /* Space between left and right columns */
}

.conteCompany {
  flex: 1; /* Take up equal space on the left side */
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.inputCompañi {
  width: 100%;
  font-weight: bold;
  height: 60px;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 26px;
  background-color: #1e1e1e;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

.inputCompañi:hover {
  box-shadow: 0px 0px 10px #ffffff;
}

.inputCompañi::placeholder {
  color: rgb(255, 255, 255);
}

/* Profile Image Section */
.profile-container {
  flex: 1; /* Take up equal space on the right side */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-image-container1{
  width:60%;
  margin-bottom: 20px;
  border-radius: 30px;
}
.profile-image-container {
  margin-bottom: 20px;
}

.background-image-container1:hover,
.profile-image-container:hover {
  box-shadow: 0px 0px 20px #5418ED;
}

.background-image {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
  padding: 20px;
  border: 2px solid #ffffff;
}

.no-image-back {
  width: 100%;
 
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 3px solid #fff;
}

.profile-image-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 3px solid #fff;
  cursor: pointer;
  margin-bottom: 20px;
}

.no-image-profile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.no-image-profile:hover {
  box-shadow: 0px 0px 10px #ffffff;
}

/* User Information */
.user-info {
  text-align: center;
  margin-top: 20px;
}

.nameCompany {
  color: #f7f7f7;
  font-size: 1.2em;
  font-weight: bold;
}

.detallesCompany {
  margin-top: 10px;
  color: #d4d3d3;
  font-size: 1.2em;
}

/* Button */
.conButtonApply1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
 
}

.glow-on-hover:hover {
  background-color: #ED18B1;
  box-shadow: 0px 0px 30px #DF18ED;
}

 

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .form-layout {
    flex-direction: column; /* Stack the columns on top of each other */
  }

  .inputCompañi,
  .profile-container {
    width: 100%; /* Full width on smaller screens */
  }

  

  .no-image-back {
    height: 150px;
  }

  .profile-image-container {
    width: 80px;
    height: 80px;
  }

  .nameCompany,
  .detallesCompany {
    font-size: 1em;
  }
}

@media only screen and (max-width: 767px) {
  .containerFormCompany {
    padding: 0; /* Remove padding on mobile */
  }

  .goback78{
    margin-bottom: 10px;
  }
}

.icon79{
  font-size: 30px; /* Smaller icon size */
  color: #ffffff; /* Slightly lighter color */
 
}

.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
  
}

.input-with-icon .icon {
  position: absolute;
  left: 15px; /* Adjust this value based on your design */
  color: #fff;
  font-size: 20px;
}

.input-with-icon .inputCompañi {
  padding-left: 45px; /* Make room for the icon */
}


.b798 {
  width: 150px;
  height: 50px;
  border: none;
  outline: none;
  color: #f7f6f6;
  background: #CDED17;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px;
  margin: 10px;
}

.t768{
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.g223g{
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}

.b798:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff007a, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 50px;
}

.b798:active {
  color: #000;
}

.b798r:active:after {
  background: transparent;
}

.b798:hover:before {
  opacity: 1;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}


.goback78 {
  background: none; /* Remove background */
  border: none; /* Add a border with the color of your choice */
  border-radius: 10px; /* Add border radius */
  padding: 10px 10px; /* Add padding for better appearance */
  color: #007BFF; /* Set the text color */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s ease; /* Add a transition for hover effect */
}

.goback78:hover {
  background-color: #5418ED ; /* Change background on hover */
  color: #fff; /* Change text color on hover */
}

.button-container {
  display: flex;
  justify-content: space-between; /* Space out the buttons */
  align-items: center; /* Align buttons vertically if needed */
  width: 100%; /* Make sure the container spans the full width */
}

.c790{
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
}

 