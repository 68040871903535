.aling-conte-input{
    text-align: center; /* This centers inline elements or inline-block elements */
   
  }
.containerButtonWelcome{
    margin-top: 20px;
    padding-left: 25px;
    position: absolute;
  }
  
.image-upload-container {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    margin-top: 60px; 
    margin-bottom: 60px;
   
}

.box-decoration {
    border-radius: 5px;
    padding: 10px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.image-upload-label {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;

    
}

.image-upload-input {
    display: none;
}

.image-upload-button {
    background-color: #19d01f;
    border: none;
    color: white;
    padding: 0.8rem 1.2rem;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.image-upload-button:hover {
    background-color: #3e8e41;
}

.img-display-before {
    height: 100px;
    width: 100px;
    background-color: rgb(221, 222, 222);
    border-radius: 50px;
}

.img-display-after {
    height: 100px;
    width: 100px;
    border-radius: 100%;
}
.containerButtonSend{
padding: 30px;
}
.back{
    margin-top: 40px;
    width: 500px;
    border-radius: 20px;
}

.container-background{
    margin-top: 20px;
   display: flex;
  
}

.image-background{
    width: 500px;
    border-radius: 20px;
}

.image-Profile{
    width: 120px;
    height: 120px;
    border-radius: 100px;
}

.con-pre-back{
     width: 500px;
     height: 150px;
     background-color: rgb(243, 244, 245);
     border-radius: 20px;
}

.con-pre-profile{
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-color: rgb(243, 244, 245);
}


.profile-container6 {
    max-width: 500px;
    margin: auto;
    margin-top: 10PX;

   
    
  }
  
  .background-image-container {
    position: relative;
    width: 100%;
    height: 200px; /* Adjust the height as needed */
  }
  
 

  .background-image1 {
    width: 100%;
    height: 100%;
    border-radius: 20PX;
    object-fit: cover; /* Cover the container without stretching */
    padding: 20PX;
     
  }
.ceterl{
  
   
}

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  
  .no-image-back {
    width: 400PX;
    height: 220PX;
    
    flex-direction: column; /* Arrange children vertically */
  justify-content: center;
  align-items: center;
    border-radius: 30PX;
  }
  

  
  .profile-image-container1 {
    width: 120px; /* Set a fixed size for the profile image */
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff; /* Background color */
    border: 3px solid #fff; /* Border to create separation from the background */
    cursor: pointer;
  }
  

  .ndd {
    margin-top: 10px;
    color: #fff;
  }
  
   
.user-info{
    text-align: center;
    
}

  
  .user-name, .user-location, .user-contact {
    margin-top: 5PX;
    padding: 5px 0;
    border-bottom: 1px solid #eee; /* subtle line under each item */
  }
  
  /* Last child should not have a border-bottom */
  .user-contact {
    border-bottom: none;
  }
  
  /* Add some more styles for aesthetics */
  .user-name >   .user-location >   .user-contact >  {
   
    margin-right: 5px;
  }
  
  /* Style the user's name to stand out */
  .user-name {
    color: #333;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  /* Style the city to be a bit more casual */
  .user-location {
    font-style: italic;
    color: #666;
  }
  
  /* Style for phone number */
  .user-contact {
    color: #333;
    font-weight: bold;
  }
 
  

  .inputTexSet{
    width: 90%;
    height: 60px;
    padding: 12px 20px;
    margin: 5px 0;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #000000;
    border: none;
    color:#fff;
    font-size: 18px;
  }

  