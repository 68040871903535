/*
.containerTipeacount {
  display: flex;
  height: 100vh;  
  align-items: center;  
  justify-content: center;  
  
}

.column {
  flex: 1;  
  display: flex;
  flex-direction: column;  
  align-items: center;  
  justify-content: center;  
  padding: 20px;  
}

*/
 
 
.containerTipeacount {
  display: flex;
  height: 100vh;  
  align-items: center;  
  justify-content: center;  
}

 
.column {
  flex: 1;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  padding: 20px; /* Add some padding */

 


}

 
@media only screen and (max-width: 600px) {
  .containerTipeacount {
    display: flex;
    flex-direction: column;
    overflow-y: scroll; /* Enable vertical scrolling if needed */
  }

  .column {
    width: 100%;
    padding: 10px; /* Adjust padding as needed */
  }
}


  .column-white {
    background-color: #ffffff ;
    
  }
  
  .column-black {
    background-color: black; /* Set the background color to black */
     
  }

  /* Apply the style to desktop screens (min-width: 601px) */
@media only screen and (min-width: 601px) {
  .column-black {
    background-color: black;
    min-height: 100%;
  }
}




  
  .minimalistic-button {
    padding: 10px 20px;
    background-color: #fff;
   
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .minimalistic-button:hover {
    background-color: #070707;
  }
  
.Contop-left-button{
  margin: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

  .top-left-button {
    border-radius: 30px;
    background-color: #000000;
     
    padding: 10px 1px;
    color: #0f0a0a;
    border: none;
    
    
    
  }
/* margin: 10px;*/
  .TitleJobs{
    text-align: center;
    font-size: 25px;
    color: #eeeeee;
    font-weight: BOLD;
     
  }

  .subJobs{
    color: #cfcfcf;
    font-size: 15px;
    text-align: end;
  
     
  }

  .companys{
    color: #cfcfcf;
    font-size: 18px;
    text-align: center;
    margin-top: 30PX;
  }

  .user{
    color: #6f6f6f;
    font-size: 18px;
    text-align: center;
    margin-top: 30PX;
  }


  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: black; }
  }
  
  .typing-effect   {
    border-right: 2px solid transparent; /* The caret (cursor) */
    white-space: nowrap; /* Keeps the content on a single line */
    overflow: hidden; /* Ensures the content is clipped */
    width: 12.5ch; /* Width of the content, adjust as needed */
    animation: 
      typing 4s steps(80, end), /* Typing effect duration and steps */
      blink-caret .75s step-end 1 ; /* Caret blinking effect */
  }

  .typing-effect2   {
    border-right: 2px solid transparent; /* The caret (cursor) */
    white-space: nowrap; /* Keeps the content on a single line */
    overflow: hidden; /* Ensures the content is clipped */
    width: 30ch; /* Width of the content, adjust as needed */
    animation: 
      typing 4s steps(100, end), /* Typing effect duration and steps */
      blink-caret .75s step-end 1 ; /* Caret blinking effect */
  }



  @keyframes glowingPulse {
    0%, 100% {
      box-shadow: 0 0 20px #606060, 0 0 40px #a0a0a0, 0 0 60px #a0a0a0; /* Grey */
    }
    50% {
      box-shadow: 0 0 30px #d3d3d3, 0 0 50px #d3d3d3, 0 0 70px #d3d3d3; /* Lighter Grey */
    }
  }
  
  


  .futuristic-button-pulse {
    background:  #030202;
    color: rgb(255, 255, 255);
    border: none;
    padding: 150px; /* Adjust padding to fit your design */
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    transition: transform 0.1s;
      /* This makes the button circular */
    width: 200px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: glowingPulse 8s linear infinite, colorShiftBackground 8s linear infinite;
  }

 
  .futuristic-button-pulse1 {
    background-color: #000000; /* A neutral background color */
    color: rgb(0, 0, 0);
    border: none;
    padding: 40px;
    
    
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    transition: transform 0.1s;

    display: flex;
    align-items: center;
    justify-content: center;
   
  
    animation: glowingPulse 8s linear infinite, colorShiftBackground 8s linear infinite;
     
    
  }


  @keyframes pulseAnimation {
    0% {
      transform: scale(1); /* No scaling at the start of the animation */
    }
    50% {
      transform: scale(1.05); /* Slightly enlarge the text */
    }
    100% {
      transform: scale(1); /* Return to the original scale */
    }
  }


  .texLookingJob { 
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    position: relative; /* Positioning context for text */
    text-shadow: 0 0 20px #ffffff; /* Green glow effect for the text */
    animation: pulseAnimation 2s infinite; /* Animation for a subtle pulse effect */
    color: #fff;
  }

  .jo79 { 
    text-align: center;
    font-size: 15px; 
  }


  .texCompany {
    border: 1px solid #ffffff;
    border-radius: 20px;
    background-color: initial;
    padding: 20PX;
    color: #f5f5f5;
    text-align: left;
    font-size: 30px;
    position: relative; /* Positioning context for text */
    text-shadow: 0 0 20px #ffffff; /* Green glow effect for the text */
    animation: pulseAnimation 2s infinite; /* Animation for a subtle pulse effect */
    box-shadow: 0px 0px 10px 10px rgb(255, 255, 255);
  }
  
  .futuristic-button-pulse1:hover   {
    /* You can add hover effects if you like */
    transform: scale(1.05);
  }

  .futuristic-button-pulse:hover   {
    /* You can add hover effects if you like */
    transform: scale(1.05);
  }

  
  @media (max-width: 600px) {
    .futuristic-button-pulse1 {
      margin-left: 20px;  /* Add horizontal margin for mobile */
      margin-right: 20px; /* Add horizontal margin for mobile */ 
    }

    .texCompany {
      margin-left: 20px;  /* Add horizontal margin for mobile */
      margin-right: 20px; /* Add horizontal margin for mobile */
    }
  }

  /* TipeAccount.css */

/* TipeAccount.css */.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #c0aaf8;
  padding: 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
margin-left: 20px;
margin-right: 20px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.modal-button {
  background-color: #000000; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove borders */
  border-radius: 15px; /* Rounded corners */
  font-size: 16px; /* Increase font size */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  display: inline-block; /* Ensures button behaves properly within its container */
  text-align: center; /* Centers the text within the button */
  font-weight: bold;
  margin-top: 10px;
}

.modal-button:hover {
  background-color: #9A18ED ; /* Darker green on hover */
}

.company-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add space below the image */
}
 
  
 