/* Container for posts */
.container333 {
  display: flex;
  flex-wrap: wrap; /* Allows columns to wrap to the next line */
  width: 100vw; 
  height: 100vh;
  margin: 0 auto;
}

/* Column styling */
.column333 {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Specific styling for left and right columns */
.left-column333 {
  background-color: #000000;
}

.right-column333 {
  background-color: #1e1e1e;
}

/* Responsive Design */

/* For mobile devices (1 column) */
@media (max-width: 767px) {
  .column333 {
    flex: 100%; /* Full width */
  }
}

/* For tablets (2 columns) */
@media (min-width: 768px) and (max-width: 1024px) {
  .column333 {
    flex: 50%; /* Two columns */
  }
}

/* For desktops (2 columns) */
@media (min-width: 1025px) {
  .column333 {
    flex: 50%; /* Two columns */
  }
}




 

 
.video-resize {
  width: 45%;
  height: 100%;
  min-width: 10%;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .video-resize {
    width: 60%; /* Make the video width larger on mobile */
    min-width: unset; /* Remove minimum width constraint */
    height: auto; /* Adjust height automatically to maintain aspect ratio */
  }
}

/* Optional: Adjust styles for tablets or medium screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .video-resize {
    width: 50%; /* Adjust width for tablets or medium screens */
    min-width: unset;
    height: auto; /* Maintain aspect ratio */
  }
}

.con-image-post43 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

/* Estilos para textarea */
.inputPostChido98 {
  width: 30%;
  height: 70px;
  padding: 12px 20px;
  margin: 5px 0;
   
  border-radius: 20px;
  background-color: #050505;
  border: none;
  margin: 20px;
  color: #fff;
  resize: none;
  font-weight: bold;
  font-size: 20px;
}

.inputPostChido98:focus {
  border: none;           /* Remove the border or set it to 'none' */
  outline: none;          /* Remove any outline */
}

.inputPostChido98::placeholder {
  text-align: center;
  color: #fff;
  font-size: 20px;
}

/* Contenedor principal */
 
/* Estilos para dispositivos de PC y iPads */
@media (min-width: 768px) {
  .profiles-postC {
    grid-template-columns: 1fr 1fr; /* Dos columnas */
  }
}

/* Estilos para dispositivos móviles */
@media (max-width: 767px) {
  .inputPostChido98 {
    width: 80%;
  }
}

/* Otros estilos */
.rowContainer9 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.con-sin-image-user-post {
  margin-top: 20px;
  width: 40px;
  height: 20px;
  background-color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  animation-name: resizeAnimation;
  animation-duration: 15s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes resizeAnimation {
  0% {
    width: 125px;
    height: 110px;
  }
  50% {
    width: 50px;
    height: 125px;
  }
  100% {
    width: 125px;
    height: 110px;
  }
}

@media (max-width: 768px) {
  @keyframes resizeAnimation {
    0% {
      width: 175px;
      height: 175px;
    }
    50% {
      width: 125px;
      height: 175px;
    }
    100% {
      width: 175px;
      height: 175px;
    }
  }
}

.progress-bar {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 10px;
  background-color: #CDED17;
  width: 0;
  transition: width 0.2s;
  color: #000;
}

 
.change-media-button {
  font-weight: bold;
  margin-bottom: 20px;
  background: #151515 ;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 15px 25px;
  cursor: pointer;
  z-index: 10; /* Ensure the button is on top of the media */
}


.media-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}



.image-post-add33{
 
  border: 2px solid #ffffff;
  width: 40%;
  height: 100%;
  border-radius: 20PX;
  object-fit: cover; /* Cover the container without stretching */
  padding: 10PX;
  
}

