.containerSuscriptionff {
    display: flex;
    flex-wrap: wrap;
}

.ttRecruitment33{
    color: #000000;
    font-weight: bold;
    font-size: 30px;
}

.ttRe11{

    color: #2e2e2e;
    font-weight: bold;
    font-size: 15px;
}

.ttmm{
    color: #646464;
    font-size: 15px;
}


.ttcanceld{
    font-size: 12px;
    color: aliceblue;
}

.BUTONttcancel{
    margin-top: 10px;
    background-color: #000000;
    color: aliceblue;
    border-radius: 20PX;
    padding: 10PX;
    border: none;
}