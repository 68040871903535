/*
.historial-container {
    max-height: 75vh;
    overflow-y: auto;
}

.more-searched {
    font-size: 18px;        
    font-weight: bold;       
    color: #333;             
    display: inline-flex;    
    align-items: center;     
}

 


 
@media (max-width: 599px) {
    .historial-container {
        max-height: 50vh;  
        
    }
}

 
@media (min-width: 600px) and (max-width: 1023px) {
    .historial-container {
        max-height: 65vh;  
        
    }
}

 
@media (min-width: 1024px) {
    .historial-container {
        max-height: 75vh;  
       
    }
}

.historial-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.historial-item {
     
    color: white;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    background-color: #121212;
    padding: 15px;
}

.historial-item:hover {
    background-color: #5418ED   ;
}

.no-history {
    color: #888;
    font-size: 16px;
}


.historial-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;  
}

 
.term-count {
    color: #f7abfc;
    margin-left: 5px;
    font-size: 12px;
}

.Buscado-anoth{
    font-size: 14px;
    color: #c5c5c5;
    margin-left: 10px;
}

.no-history {
    font-size: 14px;
    color: #999;
    
}
.userSear{
    fill:#fff;
    font-size: 8px;
}

*/
.historial-container {
    padding: 20px;
}

.more-searched {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.historial-list {
    list-style: none;
    padding: 0;
}

.historial-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.historial-item:hover {
    background-color: #f9f9f9;
}

.term-count {
    float: right;
    color: #666;
}

.userSear {
    margin-left: 5px;
    color: #333;
}
